import { useGLTF, useMatcapTexture } from "@react-three/drei";
import { CuboidCollider, RigidBody } from "@react-three/rapier";
import { degrees } from "radians";
import { useRef, useState } from "react";
import Distance from "../Distance";
import SignText from "../Texts/Signs/SignText";
import * as THREE from "three";
import useGame from "../../store/useGame";

export default function Sign(props) {
  // let model = useGLTF("./models/sign/sign.glb");
  const { nodes, materials } = useGLTF("./models/sign/sign.glb");
  const [matcap, url] = useMatcapTexture(
    570, // index of the matcap texture https://github.com/emmelleppi/matcaps/blob/master/matcap-list.json
    1024 // size of the texture ( 64, 128, 256, 512, 1024 )
  );

  return (
    <>
      <group {...props}>
        <Distance>
          <group>
            <RigidBody colliders={false} type="fixed">
              <group scale-x={props.reverse ? -1 : 1}>
                {/* <Clone
                object={model.scene}
                scale={0.2}
                rotation-y={degrees(-90)}
              /> */}

                <group scale={0.2} rotation-y={degrees(-90)}>
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Plane003.geometry}
                    material={materials.grass}
                    position={[-0.04, -0.06, -0.26]}
                    rotation={[0, 0.63, 0]}
                    scale={5.09}
                  />
                  <group position={[0.36, 6.19, 0]} scale={1.38}>
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes.Cube003.geometry}
                      material={materials.Material}
                    />
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes.Cube003_1.geometry}
                      material={materials.pin}
                    />
                  </group>
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Cube.geometry}
                    // material={materials.wood}
                  >
                    <meshMatcapMaterial matcap={matcap} />
                  </mesh>
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Circle003.geometry}
                    material={nodes.Circle003.material}
                    position={[-0.83, 0, 0.42]}
                    rotation={[0, -Math.PI / 2, 0]}
                    scale={[0.63, 1.57, 0.63]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Circle.geometry}
                    material={nodes.Circle.material}
                    position={[0.23, 0, 0.93]}
                    rotation={[Math.PI, -1.27, Math.PI]}
                    scale={[0.6, 1.48, 0.6]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Circle001.geometry}
                    material={nodes.Circle001.material}
                    position={[1.08, 0, -0.03]}
                    rotation={[0, -0.93, 0]}
                    scale={[0.72, 1.78, 0.72]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Circle004.geometry}
                    material={nodes.Circle004.material}
                    position={[-0.77, 0, -0.6]}
                    rotation={[0, -Math.PI / 2, 0]}
                    scale={[0.43, 1.07, 0.43]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Circle002.geometry}
                    material={nodes.Circle002.material}
                    position={[0.19, 0, -1.01]}
                    rotation={[0, -Math.PI / 2, 0]}
                    scale={[0.72, 1.78, 0.72]}
                  />
                </group>
              </group>
              <CuboidCollider args={[0.5, 0.8, 0.1]} position={[0, 0.8, 0]} />
            </RigidBody>
            <SignText text={props.text} />
          </group>
        </Distance>
      </group>
    </>
  );
}
