import { Center, Text, useGLTF, useTexture } from "@react-three/drei";
import { CuboidCollider, RigidBody } from "@react-three/rapier";
import { useControls } from "leva";
import { degrees } from "radians";
import { useEffect } from "react";
import Distance from "../Distance";
import FocusBox from "../FocusBox";
import * as THREE from "three";
import Link from "../Link";
import LogoCube from "./logo-cube";

export default function FAF(props) {
  let model = useGLTF("./models/faf/FAF.glb");

  let baked_texture = useTexture(
    "./textures/shadow/baked_shadow.jpg",
    (texture) => {
      texture.flipY = false;
    }
  );
  useEffect(() => {
    model.scene.traverse((k) => {
      if (k.name == "faf_floor") {
        k.material = new THREE.MeshBasicMaterial({
          map: baked_texture,
          color: "#FAFAFA",
        });
      }
    });
  }, []);
  return (
    <group {...props}>
      <Distance>
        <RigidBody type="fixed" colliders={false} position={[-1.65, 0, 0.55]}>
          <Center top>
            <group rotation-y={degrees(-100)}>
              <primitive object={model.scene} scale={1.04} />
            </group>
          </Center>
          <CuboidCollider args={[2.9, 1, 2.9]} position={[0, 1, 0]} />
        </RigidBody>
      </Distance>
      <group position={[-1.65, 0, 1.55]}>
        <Text
          scale={8}
          font="./font/carter.ttf"
          rotation-x={degrees(-90)}
          position={[0, 0.02, 3]}
        >
          {"Fair Art Fair"}
        </Text>

        <Text
          scale={5}
          font="./font/carter.ttf"
          rotation-x={degrees(-90)}
          position={[0, 0.02, 4]}
        >
          {"Role: Backend developer"}
        </Text>

        <LogoCube type="aws" position={[-2, 0, 5.5]} />
        <LogoCube type="js" position={[-0.5, 0, 6]} />

        <FocusBox
          scale={4}
          position={[0, 0, 3]}
          camera_target_position={[0, 2, -2]}
        />

        <Link url="https://fairartfair.art" position={[1, 0, 5.5]} />
      </group>
    </group>
  );
}
