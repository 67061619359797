import { Center, Text, useGLTF, useTexture } from "@react-three/drei";
import { CuboidCollider, RigidBody } from "@react-three/rapier";
import { useControls } from "leva";
import { degrees } from "radians";
import { useEffect } from "react";
import Distance from "../Distance";
import FocusBox from "../FocusBox";
import Link from "../Link";
import * as THREE from "three";
import LogoCube from "./logo-cube";

export default function IconlyWebsite(props) {
  let model = useGLTF("./models/iconly-website/monitor.glb");
  useEffect(() => {
    model.scene.traverse((k) => {
      k.castShadow = k.receiveShadow = true;
    });
  }, []);

  let baked_texture = useTexture(
    "./textures/shadow/baked_shadow.jpg",
    (texture) => {
      texture.flipY = false;
    }
  );
  useEffect(() => {
    model.scene.traverse((k) => {
      if (k.name == "website_floor") {
        k.material = new THREE.MeshBasicMaterial({
          map: baked_texture,
          color: "#FAFAFA",
        });
      }
    });
  }, []);
  return (
    <group {...props}>
      <Distance static>
        <RigidBody colliders={false} type="fixed" position={[1.37, 0, 1.3]}>
          <Center top>
            <primitive
              object={model.scene}
              scale={1}
              rotation-y={degrees(-70)}
            />
          </Center>
          <CuboidCollider args={[3, 1, 3]} position={[0, 1, 0]} />
        </RigidBody>
      </Distance>
      <group position={[1.37, 0, 1.3]}>
        <Text
          scale={8}
          font="./font/carter.ttf"
          rotation-x={degrees(-90)}
          position={[0, 0.02, 4]}
        >
          {"Iconly website"}
        </Text>
        <Text
          scale={5}
          font="./font/carter.ttf"
          rotation-x={degrees(-90)}
          position={[0, 0.02, 5.3]}
        >
          {"Role: Fullstack developer"}
        </Text>
        {/* Gsap React.js Laravel*/}
        <LogoCube
          type="gsap"
          position={[-2.5, 0, 6.5]}
          rotation-y={degrees(20)}
        />
        <LogoCube type="react" position={[4, 0, 5]} rotation-y={degrees(-10)} />
        <LogoCube
          type="laravel"
          position={[2, 0, 6.4]}
          rotation-y={degrees(-4)}
        />

        <FocusBox
          scale={4}
          position={[0, 0, 4]}
          camera_target_position={[0, 2, -2]}
        />

        <Link url="https://iconly.pro" position={[0, 0, 7]} />
      </group>
    </group>
  );
}
