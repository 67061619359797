import BioRoad from "./BioRoad";
import ContactUsRoad from "./ContactUsRoad";
import WorksRoad from "./WorksRoad";

export default function Roads(props) {
  return (
    <group {...props} position-y={0.015}>
      <BioRoad position={[0, 0, -1.2]} />
      <WorksRoad position={[-0.7,0,0.8]}/>
      <ContactUsRoad position={[0.5,0,0.8]}/>
    </group>
  );
}
