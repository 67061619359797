import { Detailed } from "@react-three/drei";
import useGame from "../store/useGame";

export default function Distance(props) {
  let player_distance = useGame((state) => state.player_distance);

  return (
    <group {...props}>
      <Detailed distances={[0, player_distance + 5]}>
        {props.children}
        <group></group>
      </Detailed>
    </group>
  );
}
