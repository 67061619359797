import { useControls } from "leva";
import { degrees } from "radians";
import Sign from "./Sign";

export default function Signs(props) {
  return (
    <group {...props}>
      <Sign text="Bio" position={[3.05, 0, 3.75]} />
      <Sign text="Say hi!" position={[-5.85, 0, 3.75]} reverse />
      <Sign
        text="Works"
        position={[-0.71, 0, 7.95]}
        rotation-y={degrees(-49.9)}
      />
    </group>
  );
}
