import { degrees } from "radians";
import FAF from "./FAF";
import IconlyApp from "./Iconly-app";
import IconlyPlugin from "./Iconly-plugin";
import IconlyWebsite from "./Iconly-website";
import SkaleWebsite from "./skale-website";

export default function Works(props) {
  return (
    <group {...props}>
      <IconlyPlugin position={[7, 0, 15]} rotation-y={degrees(-20)} />
      <FAF position={[-6, 0, 20]} rotation-y={degrees(10)}/>
      <IconlyWebsite position={[7, 0, 33]} rotation-y={degrees(-20)} />
      <SkaleWebsite position={[-6, 0, 38]} rotation-y={degrees(0)} />
      <IconlyApp position={[7, 0, 45]} rotation-y={degrees(-20)} />
    </group>
  );
}
