import { Center, Text3D, useMatcapTexture } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";
import { useControls } from "leva";
import { degrees } from "radians";
import Distance from "../../Distance";
import SplittedText from "../SplittedText";

const IconlyPluginText = (props) => {
  const [matcap, url] = useMatcapTexture(
    627, // index of the matcap texture https://github.com/emmelleppi/matcaps/blob/master/matcap-list.json
    1024 // size of the texture ( 64, 128, 256, 512, 1024 )
  );
  return (
    <Center {...props} position={[5.7, 1, 3.2]} rotation-y={degrees(-45)}>
      <group rotation={[0, 0, degrees(0)]} scale={[1.5, 1.5, 2]}>
        <SplittedText
          rigidbody_type="fixed"
          type="word"
          text={`90k+`}
          color="#1976D2"
          position={[0, 0.2, 0]}
        />
        <Distance>
          <RigidBody colliders="cuboid" type="fixed">
            <mesh position={[1.6, 0, 0.1]}>
              <boxGeometry args={[3.2, 0.2, 0.3]} />
              <meshMatcapMaterial matcap={matcap} color={"#1976D2"} />
            </mesh>
          </RigidBody>
        </Distance>
      </group>
      <group
        rotation={[degrees(0), 0, degrees(0)]}
        position={[2, 0, 0.6]}
        scale={0.6}
      >
        <SplittedText type="word" text={`downloads`} />
      </group>
    </Center>
  );
};

export default IconlyPluginText;
