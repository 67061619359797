import React, { useRef } from "react";
import { Ring, useGLTF, useMatcapTexture, useTexture } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";
import { degrees } from "radians";

export default function Link(props) {
  const { nodes, materials } = useGLTF("./models/link/link.glb");
  let [link_texture] = useTexture(["./models/link/link.png"], (textures) => {
    textures.forEach((texture) => {
      texture.flipY = false;
    });
  });

  const [matcap] = useMatcapTexture(
    627, // index of the matcap texture https://github.com/emmelleppi/matcaps/blob/master/matcap-list.json
    1024 // size of the texture ( 64, 128, 256, 512, 1024 )
  );
  return (
    <group
      {...props}
      dispose={null}
      onClick={(event) => {
        event.stopPropagation();
        setTimeout(() => {
          window.open(props.url, "_blank");
        }, 500);
      }}
    >
      <group rotation-x={degrees(-90)} position-y={0.025} scale={0.7}>
        <Ring args={[0.9, 1, 6]}>
          <meshBasicMaterial color={"white"} depthWrite={false} />
        </Ring>
        <mesh rotation-z={degrees(90)} scale={0.8}>
          <planeGeometry />
          <meshBasicMaterial
            map={link_texture}
            transparent
            color={"white"}
            depthWrite={false}
          />
        </mesh>
      </group>
      {/* <RigidBody
        type="kinematicPosition"
        position={[0, -0.7, 0]}
        colliders={"hull"}
      >
        <mesh
          castShadow
          receiveShadow
          position={[0, 0.82, 0]}
          geometry={nodes.Plane003.geometry}
          scale={0.3}
        >
          <meshBasicMaterial map={link_texture} transparent color={"black"} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle.geometry}
          scale={0.7}
        >
          <meshMatcapMaterial matcap={matcap} />
        </mesh>
      </RigidBody> */}
    </group>
  );
}

useGLTF.preload("./models/link/link.glb");
