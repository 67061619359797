import { useControls } from "leva";
import { SocialsFloor } from "./SocialsFloor";

export default function Socials(props) {
  return (
    <group {...props}>
      <SocialsFloor
        position={[-20.8, 0, 3.1]}
        // position={[0, 0, 0]}
      />
    </group>
  );
}
