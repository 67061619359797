/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Chair(props) {
  const { nodes, materials } = useGLTF("./models/room/objects/chair.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube024.geometry}
        material={materials.chair_seat}
        position={[-0.07, 0.61252, -0.95]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder.geometry}
          material={materials.chair_body}
          position={[0.11969, -0.06227, 0.03378]}
          rotation={[0, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder003.geometry}
          material={materials.chair_body}
          position={[-0.1187, -0.06227, 0.03378]}
          rotation={[Math.PI, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder001.geometry}
          material={materials.chair_body}
          position={[0, -0.07231, 0.04454]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube021.geometry}
            material={materials.chair_body}
            position={[0, -0.3842, 0.00036]}
            rotation={[-Math.PI, Math.PI / 5, -Math.PI]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube020.geometry}
            material={materials.chair_body}
            position={[0, -0.3842, 0.00036]}
            rotation={[0, 1.25664, 0]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube018.geometry}
            material={materials.chair_body}
            position={[0, -0.3842, 0.00036]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube022.geometry}
            material={materials.chair_body}
            position={[0, -0.3842, 0.00036]}
            rotation={[Math.PI, -Math.PI / 5, Math.PI]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder002_1.geometry}
            material={materials.chair_body}
            position={[0, 0.02022, 0.10812]}
            rotation={[1.43984, 0, 0]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube023.geometry}
            material={materials.chair_body}
            position={[0, -0.3842, 0.00036]}
            rotation={[0, -1.25664, 0]}
          />
        </mesh>
      </mesh>
    </group>
  );
}

useGLTF.preload("./models/room/objects/chair.glb");
