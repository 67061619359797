import Floor from "./Floor";
import FloorShadow from "./FloorShadow";

export default function FloorGroup(props) {
  return (
    <group>
      <Floor />
      <FloorShadow />
    </group>
  );
}
