import { Center } from "@react-three/drei";
import { degrees } from "radians";
import { Road1 } from "./Road1";
import { Road2 } from "./Road2";
import { Road3 } from "./Road3";

export default function BioRoad(props) {
  return (
    <group {...props}>
      <Road1 position={[6, 0, 6]} />
      <Road2 position={[12.07, 0, 6]} />
      <group position={[23, 0, 19]} rotation-y={degrees(-90)}>
        <Center top>
          <Road3 position={[0, 0, 0]} />
          <Road2 position={[6.02, 0, 0]} />
          <Road3 position={[12.05, 0, 0]} />
        </Center>
      </group>
    </group>
  );
}
