import { Loader, useProgress } from "@react-three/drei";
import { useEffect } from "react";
import useGame from "./store/useGame";

export default function LoaderScreen(props) {
  let setGameLoaded = useGame((state) => state.setGameLoaded);
  let game_loaded = useGame((state) => state.game_loaded);
  const { active, progress, errors, item, loaded, total } = useProgress();
  useEffect(() => {
    setGameLoaded(false);
    if (loaded == total) {
      setTimeout(() => {
        setTimeout(() => {
          setGameLoaded(true);
          //   console.log("loaded...");
        }, 3000);
        // console.log("Moving...");
      }, 1000);
      //   console.log(loaded, total);
    }
  }, [loaded, total]);

  return (
    <>
      {game_loaded ? null : (
        <div className="loading">
          <p>Loading ...</p>
        </div>
      )}
    </>
  );
}
