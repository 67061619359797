import { Center, useProgress } from "@react-three/drei";
import { Physics, Debug } from "@react-three/rapier";
import { degrees } from "radians";
import Bio from "./bio";
import Floor from "./Floor/Floor";
import { Player } from "./Player";
import IntroText from "./Texts/Intro";
import { useControls } from "leva";
import { Suspense, useEffect, useState } from "react";
import Works from "./works";
import Signs from "./Signs";
import * as THREE from "three";

import ShadowShader from "./shaders/ShadowShader";
import FakeShadow from "./FakeShadow";
import CustomShaders from "./shaders/CustomShaders";
import InitGeneralSounds from "./InitGeneralSounds";
import FocusBox from "./FocusBox";
import CenterFlag from "./CenterFlag";
import Roads from "./Roads";
import Socials from "./soicals";
import Guide from "./guide";
import FloorGroup from "./Floor";

let World = (props) => {
  let [enablePhysics, setEnablePhysics] = useState(!false);
  useEffect(() => {
    // setTimeout(() => {
    //   setEnablePhysics(true);
    // }, 300);
    InitGeneralSounds();
  }, []);

  return (
    <>
      <Physics timeStep="vary" paused={!enablePhysics}>
        <CustomShaders />
        {/* <Debug /> */}
        <Suspense>
          <FloorGroup />
        </Suspense>
        <Player />
        <Signs />
        <IntroText />
        <Bio />
        <Works />
        <Socials />
        <CenterFlag />
        <Roads />
        <Guide />
      </Physics>
      <FakeShadow />
    </>
  );
};

export default World;
