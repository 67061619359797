/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Walls(props) {
  const { nodes, materials } = useGLTF("./models/room/objects/room.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.wall.geometry}
        material={materials.wall}
        position={[-1.95, 2, 0]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["wall-2"].geometry}
        material={materials.wall}
        position={[0, 2, -1.95]}
        rotation={[-Math.PI / 2, 0, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube048.geometry}
        material={materials["peg board"]}
        position={[-0.01516, 1.88402, -1.90448]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube051.geometry}
        material={materials.frame}
        position={[0.19804, 1.9285, -1.88407]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["panel-0"].geometry}
          material={materials.pic}
          position={[-0.00096, 0.00024, -0.0021]}
          rotation={[Math.PI / 2, 0, 0]}
        />
      </mesh>
      <group
        position={[-0.03727, 1.74737, -1.82577]}
        rotation={[0, -1.06361, 0]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder.geometry}
          material={materials["flower pot"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder_1.geometry}
          material={materials.dirt}
        />
        <group position={[-0.00014, 0.02037, 0.00003]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube039.geometry}
            material={materials["cactus 1"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube039_1.geometry}
            material={materials["cactus 2"]}
          />
        </group>
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube052.geometry}
        material={materials.frame}
        position={[-1.88647, 1.58541, -0.17804]}
        rotation={[0, Math.PI / 2, 0]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["panel-1"].geometry}
          material={materials.landscape}
          position={[-0.00271, 0.00067, -0.00592]}
          rotation={[Math.PI / 2, 0, 0]}
        />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube050.geometry}
        material={materials.dino}
        position={[-0.26591, 1.98623, -1.82914]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.floor.geometry}
        material={materials.floor}
        position={[0.0459, 0.05, 0.04625]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane002.geometry}
        material={materials.carpet}
        position={[-0.4774, 0.104, -0.40318]}
      />
    </group>
  );
}

useGLTF.preload("./models/room/objects/room.glb");
