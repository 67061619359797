/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { Detailed, SpotLight, useGLTF } from "@react-three/drei";
import Walls from "./Walls";
import Bed from "./Bed";
import Bookshelf from "./Bookshelf";
import Chair from "./chair";
import Desk from "./desk";
import { Robot } from "./Robot";
import { degrees } from "radians";
import { CuboidCollider, RigidBody } from "@react-three/rapier";
import BioBornText from "../../Texts/Bio/BioBornText";
import Distance from "../../Distance";
import FocusBox from "../../FocusBox";

export default function Room(props) {
  return (
    <group {...props}>
      <Distance
        static
        float
        rotation-y={degrees(35)}
        position={[-1, 0, 0.48]}
        scale={0.95}
      >
        <RigidBody type="fixed" colliders={false}>
          <Walls />
          <Bed />
          <Bookshelf />
          <Chair />
          <Desk />
          {/* <Robot
            animate="Coding"
            rotation-y={degrees(45)}
            position={[0, 0.1, 0]}
          /> */}
          <CuboidCollider args={[1.6, 1, 1.7]} position={[-0.4, 1, -0.3]} />
        </RigidBody>
      </Distance>
      <BioBornText rotation-y={degrees(90)} />

      <FocusBox
        scale={5}
        position={[3, 0, -0.5]}
        camera_target_position={[-2, 2, 0]}
      />
    </group>
  );
}
