import React, { useRef } from "react";
import {
  Center as group,
  useGLTF,
  useMatcapTexture,
  useTexture,
} from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { RigidBody } from "@react-three/rapier";
import FocusBox from "../FocusBox";
import Distance from "../Distance";

export function SocialsFloor(props) {
  const { nodes, materials } = useGLTF("./models/socials/socials.glb");
  let [
    twitter_texture,
    email_texture,
    instagram_texture,
    linkedin_texture,
    github_texture,
  ] = useTexture(
    [
      "./models/socials/twitter.png",
      "./models/socials/mail.png",
      "./models/socials/instagram.png",
      "./models/socials/linkedin.png",
      "./models/socials/github.png",
    ],
    (textures) => {
      textures.forEach((texture) => {
        texture.flipY = false;
      });
    }
  );

  let links = {
    twitter: "https://twitter.com/sina_golizadeh",
    linkedin: "https://www.linkedin.com/in/sina-golizade",
    instagram: "https://www.instagram.com/sina_developer/",
    email: "mailto:sgz137692@gmail.com",
    github: "https://github.com/sina-developer",
  };

  const twitter_matcap = useMatcapTexture(
    3, // index of the matcap texture https://github.com/emmelleppi/matcaps/blob/master/matcap-list.json
    1024 // size of the texture ( 64, 128, 256, 512, 1024 )
  );
  const email_matcap = useMatcapTexture(
    432, // index of the matcap texture https://github.com/emmelleppi/matcaps/blob/master/matcap-list.json
    1024 // size of the texture ( 64, 128, 256, 512, 1024 )
  );
  const linkedin_matcap = useMatcapTexture(
    40, // index of the matcap texture https://github.com/emmelleppi/matcaps/blob/master/matcap-list.json
    1024 // size of the texture ( 64, 128, 256, 512, 1024 )
  );
  const instagram_matcap = useMatcapTexture(
    325, // index of the matcap texture https://github.com/emmelleppi/matcaps/blob/master/matcap-list.json
    1024 // size of the texture ( 64, 128, 256, 512, 1024 )
  );
  const github_matcap = useMatcapTexture(
    28, // index of the matcap texture https://github.com/emmelleppi/matcaps/blob/master/matcap-list.json
    1024 // size of the texture ( 64, 128, 256, 512, 1024 )
  );
  let floor_1 = useRef();
  let floor_2 = useRef();
  let floor_3 = useRef();
  let floor_4 = useRef();
  let floor_5 = useRef();
  useFrame((state, delta) => {
    let time = state.clock.elapsedTime;
    let y_1 = Math.sin(time) / 2 - 0.5;
    let y_2 = Math.sin(time - 4) / 2 - 0.5;
    let y_3 = Math.sin(time - 2) / 2 - 0.5;
    let y_4 = Math.sin(time - 5) / 2 - 0.5;
    let y_5 = Math.sin(time - 7) / 2 - 0.5;

    floor_1.current.setNextKinematicTranslation({
      x: props.position[0] + -0.88,
      y: props.position[1] + y_1,
      z: props.position[2] + 1.53,
    });

    floor_2.current.setNextKinematicTranslation({
      x: props.position[0] + 0,
      y: props.position[1] + y_2,
      z: props.position[2] + 0,
    });

    floor_3.current.setNextKinematicTranslation({
      x: props.position[0] + 0.88,
      y: props.position[1] + y_3,
      z: props.position[2] + 1.53,
    });

    floor_4.current.setNextKinematicTranslation({
      x: props.position[0] + 1.77,
      y: props.position[1] + y_4,
      z: props.position[2] + 0,
    });

    floor_5.current.setNextKinematicTranslation({
      x: props.position[0] + 1.77,
      y: props.position[1] + y_5,
      z: props.position[2] + 3.06,
    });
  });
  let socialClicked = (ref, name) => {
    openLink(name);
  };
  let openLink = (name) => {
    setTimeout(() => {
      window.open(links[name], "_blank");
    }, 500);
  };
  return (
    <group {...props} dispose={null}>
      {/* <meshMatcapMaterial matcap={matcap} /> */}
      <Distance>
        <group>
          <group>
            <group position={[-0.88, -0.95, 1.53]}>
              <RigidBody
                ref={floor_1}
                colliders="hull"
                type="kinematicPosition"
              >
                <group>
                  <mesh
                    name="twitter"
                    geometry={nodes.Circle003.geometry}
                    onClick={(event) => {
                      event.stopPropagation();
                      socialClicked(floor_1, "twitter");
                    }}
                  >
                    <meshMatcapMaterial matcap={twitter_matcap[0]} />
                  </mesh>
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Plane.geometry}
                    position={[0, 1.17, 0]}
                    scale={0.3}
                  >
                    <meshBasicMaterial
                      map={twitter_texture}
                      transparent
                      color={"white"}
                    />
                  </mesh>
                </group>
              </RigidBody>
            </group>
            <group>
              <RigidBody
                ref={floor_2}
                colliders="hull"
                type="kinematicPosition"
              >
                <group>
                  <mesh
                    name="email"
                    geometry={nodes.Circle.geometry}
                    onClick={(event) => {
                      event.stopPropagation();
                      socialClicked(floor_2, "email");
                    }}
                  >
                    <meshMatcapMaterial matcap={email_matcap[0]} />
                  </mesh>

                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Plane003.geometry}
                    position={[0, 1.17, 0]}
                    scale={0.3}
                  >
                    <meshBasicMaterial
                      map={email_texture}
                      transparent
                      color={"white"}
                    />
                  </mesh>
                </group>
              </RigidBody>
            </group>
            <group position={[0.88, -0.42, 1.53]}>
              <RigidBody
                ref={floor_3}
                colliders="hull"
                type="kinematicPosition"
              >
                <group>
                  <mesh
                    name="instagram"
                    geometry={nodes.Circle002.geometry}
                    onClick={(event) => {
                      event.stopPropagation();
                      socialClicked(floor_3, "instagram");
                    }}
                  >
                    <meshMatcapMaterial matcap={instagram_matcap[0]} />
                  </mesh>

                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Plane001.geometry}
                    position={[0, 1.17, 0]}
                    scale={0.3}
                  >
                    <meshBasicMaterial
                      map={instagram_texture}
                      transparent
                      // color={"purple"}
                    />
                  </mesh>
                </group>
              </RigidBody>
            </group>
            <group position={[1.77, -0.68, 0]}>
              <RigidBody
                ref={floor_4}
                colliders="hull"
                type="kinematicPosition"
              >
                <group>
                  <mesh
                    name="linkedin"
                    geometry={nodes.Circle001.geometry}
                    onClick={(event) => {
                      event.stopPropagation();
                      socialClicked(floor_4, "linkedin");
                    }}
                  >
                    <meshMatcapMaterial matcap={linkedin_matcap[0]} />
                  </mesh>
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Plane002.geometry}
                    position={[0, 1.17, 0]}
                    scale={0.3}
                  >
                    <meshBasicMaterial
                      map={linkedin_texture}
                      transparent
                      color={"white"}
                    />
                  </mesh>
                </group>
              </RigidBody>
            </group>
            <group position={[1.77, -0.66, 3.06]}>
              <RigidBody
                ref={floor_5}
                colliders="hull"
                type="kinematicPosition"
              >
                <group>
                  <mesh
                    name="github"
                    geometry={nodes.Circle004.geometry}
                    onClick={(event) => {
                      event.stopPropagation();
                      socialClicked(floor_5, "github");
                    }}
                  >
                    <meshMatcapMaterial matcap={github_matcap[0]} />
                  </mesh>

                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Plane004.geometry}
                    position={[0, 1.17, 0]}
                    scale={0.3}
                  >
                    <meshBasicMaterial
                      map={github_texture}
                      transparent
                      color={"white"}
                    />
                  </mesh>
                </group>
              </RigidBody>
            </group>
          </group>
        </group>
      </Distance>

      <FocusBox
        scale={5}
        position={[1, 0, 2]}
        camera_target_position={[0, 0, -4]}
      />
    </group>
  );
}

useGLTF.preload("./models/socials/socials.glb");
