import { mergeUniforms } from "three/src/renderers/shaders/UniformsUtils.js";
import { UniformsLib } from "three/src/renderers/shaders/UniformsLib.js";
import * as THREE from "three";
import { shaderMaterial } from "@react-three/drei";
import { extend } from "@react-three/fiber";

let uniforms = {
  ...mergeUniforms([UniformsLib.lights, UniformsLib.fog]),
  ...{
    // uColorTopRight: new THREE.Color("#3F51B5"),
    // uColorTopLeft: new THREE.Color("#5C6BC0"),
    // uColorBottomRight: new THREE.Color("#3F51B5"),
    // uColorBottomLeft: new THREE.Color("#C5CAE9"),
    // uColorTopRight: new THREE.Color("#1e6091"),
    uColorTopRight: new THREE.Color("#1a759f"),
    uColorTopLeft: new THREE.Color("#1a759f"),
    uColorBottomRight: new THREE.Color("#168aad"),
    uColorBottomLeft: new THREE.Color("#34a0a4"),
    scale: 60,
    iResolution: new THREE.Vector3(window.innerWidth, window.innerHeight, 1),
    uTexture: null,
    topColor: new THREE.Color(0x0077ff),
    bottomColor: new THREE.Color(0xffffff),
    offset: 33,
    exponent: 0.6,
    fogColor: null,
    fogNear: null,
    fogFar: null,
  },
};

let FloorShader = shaderMaterial(
  uniforms,
  `
#include <common>
      #include <fog_pars_vertex>
      #include <shadowmap_pars_vertex>
      varying vec2 vUv;
      void main() {
        vec3 objectNormal = vec3( normal );
        vec3 transformedNormal = objectNormal;
        #include <begin_vertex>
        #include <project_vertex>
        #include <worldpos_vertex>
        #include <shadowmap_vertex>
        #include <fog_vertex>
        
      vUv = uv;
      }
`,
  `
#include <common>
#include <packing>
#include <fog_pars_fragment>
#include <bsdfs>
#include <lights_pars_begin>
#include <shadowmap_pars_fragment>
#include <shadowmask_pars_fragment>
#include <dithering_pars_fragment>

uniform vec3 iResolution;
uniform float iTime;
uniform float scale;
uniform vec3 uColorTopLeft;
uniform vec3 uColorTopRight;
uniform vec3 uColorBottomLeft;
uniform vec3 uColorBottomRight;
uniform sampler2D uTexture;

varying vec2 vUv;

void mainImage( out vec3 f, in vec2 p )
{
  vec2 q = p.xy / iResolution.xy;
    
    vec3 x0y0 = uColorBottomLeft; // Bottom Left
    vec3 x1y0 = uColorBottomRight; // Bottom Right
    vec3 x0y1 = uColorTopLeft; // Top Left
    vec3 x1y1 = uColorTopRight; // Top Right
    
    vec3 x0 = mix( x0y0, x1y0, q.x );
    vec3 x1 = mix( x0y1, x1y1, q.x );
    vec3 y  = mix( x0  , x1  , q.y );
        

    vec4 textureColor = texture2D(uTexture , vUv * scale);

    y = textureColor.rgb + y;

    f = y;
}


void main() {
  // CHANGE THAT TO YOUR NEEDS
  
  vec3 finalColor = vec3(0, 0, 0);
  mainImage(finalColor, gl_FragCoord.xy);
  // ------------------------------
  vec3 shadowColor = vec3(0, 0, 0);
  float shadowPower = 0.1;
  // ------------------------------
  
  // it just mixes the shadow color with the frag color
  gl_FragColor = vec4( mix(finalColor, shadowColor, (1.0 - getShadowMask() ) * shadowPower), 1.0);
  
  #include <fog_fragment>
  #include <dithering_fragment>
}
`
);
extend({ FloorShader });

export default function FloorShaderInstance() {
  return <></>;
}
