import { Circle, Ring } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { degrees } from "radians";
import { useRef, useState } from "react";
import useGame from "../store/useGame";
import * as THREE from "three";

export default function CenterFlag(props) {
  let player_pos = useGame((state) => state.player_pos);
  let tag = useRef();
  let [centerPos] = useState(new THREE.Vector3(-1, 0, 3));
  let [showTag, setShowTag] = useState(true);
  let [smoothTagPosition] = useState(new THREE.Vector3());

  useFrame((state, delta) => {
    if (tag.current.position.distanceTo(centerPos) < 10) {
      setShowTag(false);
    } else {
      setShowTag(true);
    }
    smoothTagPosition.lerp(player_pos, 20 * delta);
    tag.current.position.copy(smoothTagPosition);
    tag.current.position.y = 0.02;
    tag.current.lookAt(centerPos);
  });

  return (
    <>
      <group ref={tag}>
        <group
          rotation-x={degrees(-90)}
          rotation-z={degrees(-90)}
          visible={showTag}
        >
          <Ring args={[1, 1.02, 32]}>
            <meshBasicMaterial transparent opacity={0.08} />
          </Ring>
          <Circle args={[0.2, 3]} scale={0.8} position-x={1.2}></Circle>
        </group>
      </group>
    </>
  );
}
