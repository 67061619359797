import { useTexture } from "@react-three/drei";
import { useControls } from "leva";
import { degrees } from "radians";

export default function FloorShadow(props) {
  let texture = useTexture("./textures/shadow/floor_shadow.png", (texture) => {
    texture.flipY = true;
  });

  return (
    <group {...props} position={[0, 0, 22]}>
      <group rotation-y={degrees(-90)}>
        <mesh rotation-x={degrees(-90)} position-y={0.02} scale={86}>
          <planeGeometry />
          <meshBasicMaterial
            map={texture}
            transparent
            depthWrite={false}
            opacity={0.3}
          />
        </mesh>
      </group>
    </group>
  );
}
