import { Center } from "@react-three/drei";
import { degrees } from "radians";
import { Road1 } from "./Road1";
import { Road2 } from "./Road2";
import { Road3 } from "./Road3";

export default function ContactUsRoad(props) {
  return (
    <group {...props}>
      <Road1 position={[-9, 0,4]} />
      <Road2 position={[-15.07, 0, 4]} />
    </group>
  );
}
