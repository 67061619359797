import {
  Center,
  GradientTexture,
  useGLTF,
  useTexture,
} from "@react-three/drei";
import { CylinderCollider, RigidBody } from "@react-three/rapier";
import { useControls } from "leva";
import { degrees } from "radians";
import { useEffect, useRef } from "react";
import Distance from "../Distance";
import FocusBox from "../FocusBox";
import * as THREE from "three";
import CreatorText from "../Texts/Bio/CreatorText";

export default function Creator(props) {
  let model = useGLTF("./models/portre/portre.glb");
  let gradient_material = useRef();
  useEffect(() => {
    model.scene.traverse((k) => {
      if (k.name == "stage") {
        k.material = gradient_material.current;
      }
      k.castShadow = k.receiveShadow = true;
    });
  }, []);

  let baked_texture = useTexture(
    "./textures/shadow/baked_shadow.jpg",
    (texture) => {
      texture.flipY = false;
    }
  );
  useEffect(() => {
    model.scene.traverse((k) => {
      if (k.name == "bio_floor") {
        k.material = new THREE.MeshBasicMaterial({
          map: baked_texture,
          color: "#FAFAFA",
        });
      }
    });
  }, []);
  return (
    <group {...props}>
      <meshStandardMaterial ref={gradient_material} color={"white"}>
        {/* <GradientTexture
          stops={[0, 1]} // As many stops as you want
          colors={["#90A4AE", "#ECEFF1"]} // Colors need to match the number of stops
          size={1024} // Size is optional, default = 1024
        /> */}
      </meshStandardMaterial>
      <Distance static>
        <group position={[1.25, 0, -3.75]}>
          <RigidBody colliders={false} type="fixed">
            <Center position-y={2.6}>
              <primitive
                object={model.scene}
                scale={1}
                position={[-17, 0, -19]}
                rotation-y={degrees(-90)}
              />
              <CreatorText />
            </Center>
            <CylinderCollider args={[1, 3]} position={[0, 1, 0]} />
          </RigidBody>
        </group>
      </Distance>

      <FocusBox
        scale={4}
        position={[0.5, 0, -1]}
        camera_target_position={[0, 4, -2]}
      />
    </group>
  );
}
