import {
  Center,
  Clone,
  GradientTexture,
  Text,
  useGLTF,
  useTexture,
} from "@react-three/drei";
import {
  CuboidCollider,
  CylinderCollider,
  RigidBody,
} from "@react-three/rapier";
import { useControls } from "leva";
import { degrees } from "radians";
import { useEffect, useRef } from "react";
import { playSound } from "../../utils/AudioController";
import Distance from "../Distance";
import FocusBox from "../FocusBox";
import * as THREE from "three";
import Link from "../Link";
import IconlyPluginText from "../Texts/Works/IconlyPluginText";
import LogoCube from "./logo-cube";

export default function IconlyPlugin(props) {
  let gradient_material = useRef();
  let model = useGLTF("./models/iconly-plugin/iconly-plugin.glb");
  let icon_model = useGLTF("./models/iconly-plugin/icon.glb");
  let icons = useTexture([
    "./models/iconly-plugin/icon (1).png",
    "./models/iconly-plugin/icon (2).png",
    "./models/iconly-plugin/icon (3).png",
    "./models/iconly-plugin/icon (4).png",
  ]);
  let icon_positions = [
    [0.73, 0, 10],
    [1.73, 0, 8],
    [4.73, 0, 7],
    [-3.73, 0, 3],
  ];
  useEffect(() => {
    model.scene.traverse((k) => {
      k.castShadow = k.receiveShadow = true;
    });
  }, []);

  let baked_texture = useTexture(
    "./textures/shadow/baked_shadow.jpg",
    (texture) => {
      texture.flipY = false;
    }
  );
  useEffect(() => {
    model.scene.traverse((k) => {
      if (k.name == "plugin_floor" || k.name == "Cube034" || k.name == "Cone") {
        k.material = new THREE.MeshBasicMaterial({
          map: baked_texture,
          color: "#FAFAFA",
        });
      }
    });
  }, []);
  return (
    <group {...props}>
      <Distance static>
        <RigidBody colliders={false} type="fixed" position={[0.73, 0, -0.1]}>
          <Center top>
            <primitive
              object={model.scene}
              rotation-y={degrees(-70)}
              scale={1}
            />
          </Center>
          <CuboidCollider args={[2.4, 1, 2.4]} position={[0, 1, 0]} />
        </RigidBody>
      </Distance>
      {icons.map((k, i) => {
        return (
          <Distance position={icon_positions[i]} key={i}>
            <RigidBody
              colliders={false}
              onCollisionEnter={(e) => {
                if (e.colliderObject.parent.name == "player") {
                  playSound("hit", Math.random());
                }
              }}
            >
              <Center>
                <Clone
                  object={icon_model.scene}
                  inject={<meshStandardMaterial map={k} />}
                  scale-y={2}
                />
              </Center>
              <CylinderCollider args={[0.1, 0.65]} mass={0.1} />
            </RigidBody>
          </Distance>
        );
      })}
      <IconlyPluginText />

      <Text
        scale={8}
        font="./font/carter.ttf"
        rotation-x={degrees(-90)}
        position={[0.73, 0.025, 4]}
      >
        {"Iconly plugin for figma"}
        <meshBasicMaterial color={"white"} depthWrite={false} />
      </Text>
      <Text
        scale={5}
        font="./font/carter.ttf"
        rotation-x={degrees(-90)}
        position={[0.73, 0.025, 5]}
      >
        {"Role: Frontend developer"}
        <meshBasicMaterial color={"white"} depthWrite={false} />
      </Text>
      {/* Js */}
      <LogoCube type="js" position={[-2.5, 0, 6.5]} />

      <FocusBox
        scale={5}
        position={[0.5, 0, 4.5]}
        camera_target_position={[0, 2, -2]}
      />

      <Link
        url="https://www.figma.com/community/plugin/861001888228800074/Iconly-v2.3"
        position={[0.73, 0, 6.5]}
      />
    </group>
  );
}
