import { Joystick } from "react-joystick-component";
import useGame from "./store/useGame";

export default function HUD() {
  let joystick_direction = useGame((state) => state.joystick_direction);
  let setJoystickJump = useGame((state) => state.setJoystickJump);
  let setJoystickDirection = useGame((state) => state.setJoystickDirection);
  let setJoystickReset = useGame((state) => state.setJoystickReset);

  let handleMove = (e) => {
    // console.log(parseInt(e.x), parseInt(e.y));
    let x = e.x;
    let y = e.y;
    let direction = (Math.atan2(y, x) / Math.PI) * 180;
    if (direction < 15 && direction > -15) {
      // console.log("rightward");
      setJoystickDirection(["rightward"]);
    } else if (direction > 15 && direction < 75) {
      // console.log("forward right");
      setJoystickDirection(["rightward", "forward"]);
    } else if (direction > 75 && direction < 105) {
      // console.log("forward");
      setJoystickDirection(["forward"]);
    } else if (direction < 165 && direction > 105) {
      // console.log("forward left");
      setJoystickDirection(["forward", "leftward"]);
    } else if (direction > 165 || direction < -165) {
      // console.log("leftward");
      setJoystickDirection(["leftward"]);
    } else if (direction < -105 && direction > -165) {
      // console.log("leftward bottom");
      setJoystickDirection(["leftward", "backward"]);
    } else if (direction < -75 && direction > -105) {
      // console.log("backward");
      setJoystickDirection(["backward"]);
    } else if (direction < -15 && direction > -75) {
      // console.log("backward right");
      setJoystickDirection(["backward", "rightward"]);
    }
  };

  let handleStop = (e) => {
    setJoystickDirection([]);
  };

  let handleJump = (e) => {
    // alert("jump");
    setJoystickJump(true);
  };
  let endJump = (e) => {
    // alert("end jump");
    setJoystickJump(false);
  };
  let handleReset = (e) => {
    // alert("jump");
    setJoystickReset(true);
  };
  let endReset = (e) => {
    // alert("end jump");
    setJoystickReset(false);
  };
  return (
    <div className="hud">
      <div className="controllers mobile">
        <Joystick
          className="joystick"
          size={100}
          // sticky={true}
          baseColor="#fff3"
          stickColor="#fffa"
          move={handleMove}
          stop={handleStop}
        />
        <div
          className="jump"
          onTouchStart={handleJump}
          onMouseDown={handleJump}
          onTouchEnd={endJump}
          onMouseUp={endJump}
        >
          <img src="./media/jump.png" />
        </div>
        <div
          className="reset"
          onTouchStart={handleReset}
          onMouseDown={handleReset}
          onTouchEnd={endReset}
          onMouseUp={endReset}
        >
          <img src="./media/reset.png" />
        </div>
      </div>
    </div>
  );
}
