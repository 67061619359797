let soundList = {};

export let add_sound = (name, howl) => {
  soundList[name] = howl;
};

export let fadeInSound = (sound_name, seconds = 1000, volume = 0.5) => {
  let sound = soundList[sound_name];
  if (!sound) return;
  sound.stop();
  sound.play();
  sound.fade(0, volume, seconds);
};

export let setVolume = (sound_name, volume = 0.5) => {
  let sound = soundList[sound_name];
  if (!sound) return;
  sound.volume(volume);
};

export let isPlaying = (sound_name) => {
  let sound = soundList[sound_name];
  if (!sound) return false;
  return sound.playing();
};

export let playSound = (sound_name, volume = null) => {
  let sound = soundList[sound_name];
  if (!sound) return;
  if (volume) {
    sound.volume(volume);
  }
  sound.play();
};
export let playSoundOnce = (sound_name, volume = null) => {
  let sound = soundList[sound_name];
  if (!sound) return;
  if (volume) {
    sound.volume(volume);
  }
  if (!sound.playing()) {
    sound.play();
  }
};
export let soundEnd = (sound_name, callback = () => {}) => {
  let sound = soundList[sound_name];
  if (!sound) return;
  sound.on("end", callback);
};

export let stopSound = (sound_name) => {
  let sound = soundList[sound_name];
  if (!sound) return;
  sound.stop();
};

export let fadeOutSound = (sound_name, seconds = 1000, volume = 0.5) => {
  let sound = soundList[sound_name];
  if (!sound) return;
  sound.fade(volume, 0, seconds);
};
