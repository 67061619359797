import { useEffect, useState } from "react";
import { useRef } from "react";
import * as THREE from "three";
import { RigidBody } from "@react-three/rapier";
import {
  Center,
  GradientTexture,
  Text3D,
  useMatcapTexture,
} from "@react-three/drei";
import Distance from "../Distance";
import { playSound, setVolume } from "../../utils/AudioController";

let SplittedText = ({
  text,
  size = 1,
  word_space = 6,
  type = "char",
  color = "white",
  rigidbody_type = "dynamic",
  position
}) => {
  let chars = text.split(type == "char" ? "" : " ");
  let text_material = useRef();
  let group = useRef();
  useEffect(() => {
    group.current.traverse((k) => {
      k.material = text_material.current;
    });
  }, []);
  // let [text_material] = useState(
  //   new THREE.MeshStandardMaterial({ color: "#2196F3" })
  // );
  let genText = () => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    let texts = [];
    let x = 0;
    let y = 0;
    for (let i = 0; i < chars.length; i++) {
      const char = chars[i];
      if (char == "\n") {
        y -= 1.5;
        x = 0;
        continue;
      }
      context.font = "carter";
      const metrics = context.measureText(char);
      if (char == "-") {
        x -= 0.2;
      }
      if (char == "+") {
        x -= 0.5;
      }
      if (char == "I") {
        x -= 0.2;
      }
      if (char == "t") {
        x -= 0.2;
      }
      texts.push({
        char,
        x,
        y,
      });

      if (char == "I") {
        x += 0.2;
      }

      x += metrics.width / word_space;
    }
    return texts;
  };
  // return (
  //   <RigidBody restitution={1} friction={0}>
  //     <Text3D
  //       castShadow
  //       receiveShadow
  //       size={size}
  //       lineHeight={0.6}
  //       material={text_material}
  //       font={"./font/carter.json"}
  //     >
  //       {text}
  //     </Text3D>
  //   </RigidBody>
  // );
  //627
  const [matcap, url] = useMatcapTexture(
    627, // index of the matcap texture https://github.com/emmelleppi/matcaps/blob/master/matcap-list.json
    1024 // size of the texture ( 64, 128, 256, 512, 1024 )
  );

  return (
    <group ref={group} position={position}>
      <meshMatcapMaterial matcap={matcap} ref={text_material} color={color}>
        {/* <GradientTexture
          stops={[0, 1]} // As many stops as you want
          colors={["#90A4AE", "#ECEFF1"]} // Colors need to match the number of stops
          size={1024} // Size is optional, default = 1024
        /> */}
      </meshMatcapMaterial>
      {genText().map((k, i) => {
        return (
          <Distance
            key={i}
            always
            visible={true}
            position-x={k.x}
            position-y={k.y}
          >
            <RigidBody
              restitution={0}
              friction={0}
              type={rigidbody_type}
              onCollisionEnter={(e) => {
                // console.log("hit!", e);
                if (e.colliderObject.parent.name == "player") {
                  playSound("hit", Math.random());
                }
              }}
            >
              {/* <Center> */}
              <Text3D
                // castShadow
                // receiveShadow
                size={size}
                lineHeight={0.6}
                // material={text_material}
                font={"./font/carter.json"}
              >
                {k.char}
              </Text3D>
              {/* </Center> */}
            </RigidBody>
          </Distance>
        );
      })}
    </group>
  );
};

export default SplittedText;
