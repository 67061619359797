import React, { useRef } from "react";
import { Center, Clone, useGLTF } from "@react-three/drei";
import Distance from "../Distance";

export function Road2(props) {
  const { scene, materials } = useGLTF("./models/roads/road2.glb");
  return (
    <group {...props} dispose={null}>
      <Distance>
        <Center top scale={0.4} position-z={-0.2}>
          <Clone object={scene}  inject={<meshBasicMaterial color={"#5ac6d5"} />} />
        </Center>
      </Distance>
    </group>
  );
}

useGLTF.preload("./models/roads/road2.glb");
