import { Center, Text3D } from "@react-three/drei";
import { degrees } from "radians";

const SignText = (props) => {
  return (
    <Center {...props} position={[0, 1.25, 0.1]} scale={0.15}>
      <Text3D
        lineHeight={0.6}
        // material={text_material}
        font={"./font/carter.json"}
      >
        {props.text}
        <meshBasicMaterial color={"#474747"} />
      </Text3D>
    </Center>
  );
};

export default SignText;
