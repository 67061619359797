import { Clone, useGLTF, useTexture } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { RigidBody, CylinderCollider } from "@react-three/rapier";
import { degrees } from "radians";
import { forwardRef, useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { Robot } from "./Room/Robot";
import BioBornText from "../Texts/Bio/BioBornText";
import TechText from "../Texts/Bio/TechText";
import Distance from "../Distance";
import useGame from "../../store/useGame";
import FakeShadowObj from "../../utils/FakeShadowObj";
import FocusBox from "../FocusBox";

export default function Techs(props) {
  let addShadowObjects = useGame((state) => state.addShadowObjects);
  let group = useRef();
  let icons_group = useRef();
  let robot_body = useRef();
  let icons = useTexture([
    "./textures/icons/css.png",
    "./textures/icons/html.png",
    "./textures/icons/js.png",
    "./textures/icons/laravel.png",
    "./textures/icons/nodejs.png",
    "./textures/icons/npm.png",
    "./textures/icons/php.png",
    "./textures/icons/react.png",
    "./textures/icons/sql.png",
  ]);
  let { nodes } = useGLTF("./models/icons/icon.glb");

  useEffect(() => {
    addShadowObjects(
      FakeShadowObj(
        "Tech Robot",
        robot_body.current,
        group.current,
        2,
        2,
        0.6,
        0.2
      )
    );
  }, []);

  useFrame((state, delta) => {
    let speed = delta / 3;
    icons_group.current.rotation.z += speed;
    icons_group.current.children.forEach((k) => {
      k.rotation.z -= speed;
    });
    group.current.position.y = Math.sin(state.clock.elapsedTime) * 0.3;
  });

  return (
    <group {...props}>
      <group ref={group}>
        <group ref={icons_group} scale={0.7} position-y={3} position-z={-1}>
          {icons.map((k, i) => {
            return (
              <Distance
                key={i}
                position={[
                  Math.sin(degrees((i * 360) / icons.length)) * 2,
                  Math.cos(degrees((i * 360) / icons.length)) * 2,
                  0,
                ]}
              >
                <Clone
                  scale={0.6}
                  rotation-x={degrees(90)}
                  rotation-y={degrees(180)}
                  rotation-z={degrees(180)}
                  castShadow
                  object={nodes.Circle}
                >
                  <meshBasicMaterial map={k} />
                </Clone>
              </Distance>
            );
          })}
        </group>
        <Distance static>
          <RigidBody ref={robot_body} colliders={false} type="fixed">
            <Robot
              animate={"Yoga"}
              face={"yoga"}
              rotation-y={degrees(90)}
              scale={3}
            />
            <CylinderCollider args={[1, 1]} position={[0, 1, 0.3]} />
          </RigidBody>
        </Distance>
      </group>
      <TechText scale-y={0.5}/>
      
      <FocusBox
        scale={4}
        position={[0, 0, 2]}
        camera_target_position={[0, 2, -2]}
      />
    </group>
  );
}
