import { useFrame } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import useGame from "../store/useGame";
import CalcWorldPos from "../utils/CalcWorldPos";
import * as THREE from "three";
import { useControls } from "leva";

export default function FocusBox(props) {
  let player_pos = useGame((state) => state.player_pos);
  let setCameraTarget = useGame((state) => state.setCameraTarget);
  let camera_target = useGame((state) => state.camera_target);
  let origin_radius = 1;
  let [world_pos] = useState(new THREE.Vector3());
  let [target_pos] = useState(new THREE.Vector3());
  let area = useRef();
  let target = useRef();
  let { debug } = useControls("Focus Zones", {
    debug: { value: false },
  });

  useEffect(() => {
    world_pos.copy(CalcWorldPos(area.current));
    target_pos.copy(CalcWorldPos(target.current));
  }, []);

  useFrame(() => {
    // player_pos
    let d = player_pos.distanceTo(world_pos);
    let radius = area.current.scale.x * origin_radius;
    if (d < radius) {
      setCameraTarget(target_pos);
    } else if (camera_target == target_pos) {
      setCameraTarget(null);
    }
  });

  return (
    <group position={props.position}>
      <mesh ref={area} {...{ ...props, position: [0, 0, 0] }} visible={debug}>
        <sphereGeometry args={[origin_radius, 8, 4]} />
        <meshBasicMaterial wireframe />
      </mesh>
      <mesh
        ref={target}
        position={props.camera_target_position}
        visible={debug}
      >
        <boxGeometry />
        <meshBasicMaterial color="red" />
      </mesh>
    </group>
  );
}
