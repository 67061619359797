import { useFrame } from "@react-three/fiber";
import { useHelper } from "@react-three/drei";
import { useRef } from "react";
import * as THREE from "three";
import { radians } from "radians";
import useGame from "../store/useGame";
import { useEffect } from "react";

export default function Light(props) {
  let setShadowLightRef = useGame((state) => state.setShadowLightRef);
  let light = useRef();
  // useHelper(light, THREE.DirectionalLightHelper, 1);

  useEffect(() => {
    setShadowLightRef(light.current);
  }, []);

  useFrame((state) => {
    if (!state.camera.userData.target_position) return;
    if (!state.camera.userData.default_camera_position) return;
    let target_pos = state.camera.userData.target_position;
    let camera_pos = state.camera.userData.default_camera_position;
    light.current.position.z = camera_pos.z - 2;
    light.current.position.x = camera_pos.x + 6;
    light.current.target.position.x = target_pos.x;
    light.current.target.position.z = target_pos.z;
    light.current.target.updateMatrixWorld();
  });

  return (
    <>
      <directionalLight
        castShadow
        ref={light}
        // ref={directionalLightRef}
        position={[-2, 12, 6]}
        intensity={0.2}
        // intensity={0}
        // color={"black"}
        shadow-normalBias={0.15}
        shadow-mapSize={[1024, 1024]}
        shadow-camera-near={1}
        shadow-camera-far={20}
        shadow-camera-top={10}
        shadow-camera-right={15}
        shadow-camera-left={-15}
        shadow-camera-bottom={-10}
      />
      <ambientLight intensity={0.4} />
    </>
  );
}
