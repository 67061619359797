import { degrees } from "radians";
import Room from "./Room/Room";
import Creator from "./creator";
import Techs from "./techs";

export default function Bio(props) {
  return (
    <group {...props}>
      <Room rotation-y={degrees(-90)} position={[20, 0, 2]} />
      <Techs rotation-y={degrees(-30)} position={[29, 0, 20]} />
      <Creator rotation-y={degrees(10)} position={[17, 0, 28]} />
    </group>
  );
}
