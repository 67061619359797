import { Center, Text, useGLTF, useTexture } from "@react-three/drei";
import {
  BallCollider,
  CuboidCollider,
  CylinderCollider,
  RigidBody,
} from "@react-three/rapier";
import { useControls } from "leva";
import { degrees } from "radians";
import { useEffect, useRef } from "react";
import * as THREE from "three";
import useGame from "../../store/useGame";
import FakeShadowObj from "../../utils/FakeShadowObj";
import Distance from "../Distance";
import FakeShadow from "../FakeShadow";
import FocusBox from "../FocusBox";
import Link from "../Link";
import LogoCube from "./logo-cube";

export default function SkaleWebsite(props) {
  let model = useGLTF("./models/skale/skale.glb");
  let planet_model = useGLTF("./models/skale/planet.glb");
  let rocket_model = useGLTF("./models/skale/rocket.glb");
  let planet_body = useRef();
  let planet_mesh = useRef();
  let rocket_body = useRef();
  let rocket_mesh = useRef();

  let addShadowObjects = useGame((state) => state.addShadowObjects);

  useEffect(() => {
    model.scene.traverse((k) => {
      k.castShadow = k.receiveShadow = true;
    });
    planet_model.scene.traverse((k) => {
      k.castShadow = k.receiveShadow = true;
    });
    rocket_model.scene.traverse((k) => {
      k.castShadow = k.receiveShadow = true;
    });

    addShadowObjects(
      FakeShadowObj(
        "Planet",
        planet_body.current,
        planet_mesh.current,
        1,
        1,
        0.6,
        0.1
      )
    );
    addShadowObjects(
      FakeShadowObj(
        "Rocket",
        rocket_body.current,
        rocket_mesh.current,
        0.5,
        0.5,
        0.6,
        0.1
      )
    );
  }, []);
  
  let baked_texture = useTexture(
    "./textures/shadow/baked_shadow.jpg",
    (texture) => {
      texture.flipY = false;
    }
  );
  useEffect(() => {
    model.scene.traverse((k) => {
      if (k.name == "skale_floor") {
        k.material = new THREE.MeshBasicMaterial({
          map: baked_texture,
          color: "#FAFAFA",
        });
      }
    });
  }, []);
  return (
    <group {...props}>
      <Distance static>
        <RigidBody colliders={false} type="fixed" position={[-1.38, 0, 3.5]}>
          <Center top>
            <primitive
              scale={1.025}
              object={model.scene}
              rotation-y={degrees(-90)}
            />
          </Center>
          <CuboidCollider args={[3, 1, 3]} position={[0, 1, 0]} />
        </RigidBody>
      </Distance>
      <group position={[-1.38, 0, 3.5]}>
        <RigidBody ref={planet_body} colliders={false} position={[3, 0, 6.5]}>
          <Distance>
            <Center>
              <primitive ref={planet_mesh} object={planet_model.scene} />
            </Center>
          </Distance>
          <BallCollider args={[0.6]} mass={0.2} />
        </RigidBody>
        {/* <FakeShadow
        name="planet_shadow"
        target_position={planet_body.current}
        target_rotation={planet_mesh.current}
        width={2}
        height={2}
      /> */}
        <RigidBody ref={rocket_body} colliders={false} position={[-4, 0, 5]}>
          <Distance>
            <Center>
              <primitive ref={rocket_mesh} object={rocket_model.scene} />
            </Center>
          </Distance>
          <CylinderCollider
            args={[0.6, 0.3, 1]}
            position={[0, 0, 0]}
            mass={0.1}
          />
        </RigidBody>
        <Text
          scale={8}
          font="./font/carter.ttf"
          rotation-x={degrees(-90)}
          position={[0, 0.02, 4]}
        >
          {"Skale website"}
        </Text>
        <Text
          scale={5}
          font="./font/carter.ttf"
          rotation-x={degrees(-90)}
          position={[0, 0.02, 5.3]}
        >
          {"Role: Frontend developer"}
        </Text>
        {/* Webflow gsap */}
        <LogoCube
          type="webflow"
          position={[-1, 0, 7]}
          rotation-y={degrees(-20)}
        />
        <LogoCube type="gsap" position={[1, 0, 7.2]} rotation-y={degrees(4)} />

        <FocusBox
          scale={5}
          position={[0, 0, 4]}
          camera_target_position={[0, 2, -2]}
        />
        <Link url="https://skale.space" position={[-3, 0, 7]} />
      </group>
    </group>
  );
}
