import * as THREE from "three";

export default {
  uniforms: {
    iTime: 0,
    iResolution: {
      value: new THREE.Vector3(window.innerWidth, window.innerHeight, 1),
    },
  },

  vertexShader: `
    #include <common>

    varying vec2 vUv;
    void main() {
      
    vUv = uv;
    }
  `,

  fragmentShader: `
  
    #include <common>
    #define ALT  0.48

    uniform vec3 iResolution;
    uniform float iTime;

    varying vec2 vUv;
    
    void mainImage( out vec4 fragColor, vec2 p)
    {
        
      vec2 uv = p / iResolution.xy;
        float t = sin(iTime);
        uv = .5 * sin(uv * PI) + .5;
        float f = ALT * (t + 1.0);
        float s = smoothstep(f, 1.0, uv.x * uv.y);
        p = step(2.,mod(p.xy, 3.));
        
        fragColor = p.x * p.y * vec4(0., .7*s, 1.*s, 1.);
    }
    
    
    

    void main() {
      // mainImage(gl_FragColor, gl_FragCoord.xy);
    }
  `,
};
