import { useControls } from "leva";
import Keys from "./keys";

export default function Guide(props) {
  return (
    <group {...props}>
      <Keys position={[7.10, 0, 0.15]} />
    </group>
  );
}
