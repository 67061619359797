/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef, useState } from "react";
import {
  useGLTF,
  useAnimations,
  useKeyboardControls,
  OrbitControls,
  Clone,
  Ring,
} from "@react-three/drei";
import { degrees, radians } from "radians";
import { useControls } from "leva";
import { useFrame, useThree } from "@react-three/fiber";
import {
  useRapier,
  RigidBody,
  CuboidCollider,
  CapsuleCollider,
} from "@react-three/rapier";
import * as THREE from "three";
import gsap from "gsap";
import useGame from "../store/useGame";
import CalcWorldPos from "../utils/CalcWorldPos";
import FakeShadow from "./FakeShadow";
import FakeShadowObj from "../utils/FakeShadowObj";
import { Howl, Howler } from "howler";
import {
  add_sound,
  fadeInSound,
  fadeOutSound,
  isPlaying,
  playSound,
  playSoundOnce,
  stopSound,
  setVolume,
} from "../utils/AudioController";
gsap.defaults({ overwrite: "auto" });

export function Player(props) {
  let { enable_orbit } = useControls("Player", {
    enable_orbit: { value: false },
  });

  const group = useRef();
  let orbit = useRef();
  let camera_target_mesh = useRef();
  let setPlayerPos = useGame((state) => state.setPlayerPos);
  let joystick_direction = useGame((state) => state.joystick_direction);
  let addShadowObjects = useGame((state) => state.addShadowObjects);
  let camera_target = useGame((state) => state.camera_target);
  let init_pos = new THREE.Vector3(0, 2, 1);

  const { scene, nodes, materials, animations } = useGLTF(
    "./models/robot/robot2.glb"
  );

  let [skate_sound, setSkateSound] = useState();
  let [skate_jump_sound, setSkateJumpSound] = useState();
  let [jump_sound, setJumpSound] = useState();
  let [hi_sound, setHiSound] = useState();
  let [robot_sound, setRobotSound] = useState();

  let [smoothedCameraPosition] = useState(() => new THREE.Vector3(0, 0, 0));
  let [smoothedCameraTarget] = useState(() => new THREE.Vector3());
  let [smoothedOrbitTarget] = useState(() => new THREE.Vector3());
  let [smoothedMinDistance] = useState(() => new THREE.Vector3());
  let [is_moving, setIsMoving] = useState(false);
  let [is_jumping, setIsJumping] = useState(false);
  let [head_bone, setHeadBone] = useState();
  let [is_orbiting, setIsOrbiting] = useState(false);
  let [smooth_camera_gsap, setSmoothCameraGsap] = useState(null);
  const { actions, names } = useAnimations(animations, scene);

  let animationOptions = [
    "idle",
    "skating",
    "Handshake",
    "jump",
    "Coding",
    "Thinking",
    "Sleeping",
  ];
  let [animationName, setAnimationName] = useState(animationOptions[0]);

  let soundList = {
    skate_jump: skate_jump_sound,
    jump: jump_sound,
    skate: skate_sound,
    hi: hi_sound,
    robot: robot_sound,
  };
  // let { animationName } = useControls("animation", {
  //   animationName: { options: names },
  // });

  useEffect(() => {
    Object.values(nodes).forEach((mesh, i) => {
      if (mesh.isBone) {
        if (mesh.name == "Bone007") {
          setHeadBone(mesh);
        }
      }
      // mesh.castShadow = true;
      // mesh.receiveShadow = true;
      mesh.frustumCulled = false;
    });

    addShadowObjects(
      FakeShadowObj(
        "Player",
        body.current,
        mesh.current,
        0.4,
        0.5,
        0,
        0.1,
        "rect"
      )
    );

    // if (!props.animated) return;
    // setAnimationName(animationOptions[0]);

    setPlayerPos(body.current.translation());
  }, []);

  useEffect(() => {
    // if (!props.animated) return;
    let action = actions[animationName];
    // if (poseFixes[animationName]) {
    //   poseFixes[animationName]();
    // }
    action.reset().fadeIn(0.2).play();
    return () => {
      action.fadeOut(0.2);
    };
  }, [animationName]);

  useEffect(() => {
    if (is_moving) {
      fadeInSound("skate", 500, 0.1);
      // setVolume("skate" , 0.2)
      // playSound("skate");

      setAnimationName("skating");
    } else {
      fadeOutSound("skate", 500, 0.1);
      // pauseSound("skate");
      setAnimationName("idle");
    }
    stopSound("robot");
  }, [is_moving]);

  useEffect(() => {
    if (is_jumping) {
      // playSoundOnce("jump");
      setAnimationName("jump");
    } else {
      if (is_moving) {
        setAnimationName("skating");
      } else {
        setAnimationName("idle");
      }
    }
    stopSound("robot");
  }, [is_jumping]);

  // let playSound = (sound, fade = true) => {
  //   let sounds = soundList;
  //   let id1 = sounds[sound]?.play();
  //   console.log(sound, sounds[sound]);
  //   if (fade && !sounds[sound].is_paused) {
  //     sounds[sound]?.fade(0, 1, 1000, id1);
  //   }
  //   if (sounds[sound]) sounds[sound].is_paused = false;
  // };

  // let stopSound = (sound) => {
  //   let sounds = soundList;
  //   sounds[sound]?.stop();
  // };

  // let pauseSound = (sound, fade = true) => {
  //   let sounds = soundList;
  //   if (fade) {
  //     sounds[sound]?.fade(1, 0, 1000);
  //     // let id1 = sounds[sound]?.pause();
  //   } else {
  //     sounds[sound]?.pause();
  //     if (sounds[sound]) sounds[sound].is_paused = true;

  //     if (sounds[sound]) console.log("=>" + sound, sounds[sound].is_paused);
  //   }
  // };

  let [subscribeKeys, getKeys] = useKeyboardControls();
  let body = useRef();
  let head = useRef();
  let mesh = useRef();
  let { rapier, world } = useRapier();
  let rapierWorld = world.raw();

  useEffect(() => {
    let unsubscribeJump = subscribeKeys(
      (state) => state.jump,
      (value) => {
        if (value) {
          jump();
        }
      }
    );

    let unsubscribeJoystickJump = useGame.subscribe(
      (state) => state.joystick_jump,
      (value) => {
        if (value) {
          jump();
        }
      }
    );
    turnToCamera();
    return () => {
      unsubscribeJump();
      unsubscribeJoystickJump();
    };
  }, []);

  useEffect(() => {
    let unsubscribeReset = subscribeKeys(
      (state) => state.reset_char,
      (value) => {
        if (value) {
          resetChar();
        }
      }
    );
    let unsubscribeJoystickReset = useGame.subscribe(
      (state) => state.joystick_reset,
      (value) => {
        if (value) {
          resetChar();
        }
      }
    );
    return () => {
      unsubscribeReset();
      unsubscribeJoystickReset();
    };
  }, []);

  let resetChar = () => {
    let pos = init_pos;
    setPlayerPos(pos);
    body.current.setLinvel({ x: 0, y: 0, z: 0 });
    body.current.setAngvel({ x: 0, y: 0, z: 0 });
    body.current.setTranslation(pos);
    mesh.current.rotation.y = degrees(-135);
    setPlayerPos(body.current.translation());
  };
  let jump = () => {
    const origin = body.current.translation();
    origin.y -= 0.06;
    let direction = { x: 0, y: -1, z: 0 };
    let ray = new rapier.Ray(origin, direction);

    const hit = rapierWorld.castRay(ray, 10, true);
    if (hit && hit.toi < 0.05) {
      body.current.applyImpulse({ x: 0, y: 0.35, z: 0 });
    }
  };

  let checkJoystickDir = (dir) => {
    return joystick_direction.includes(dir);
  };

  let turnToCamera = () => {
    mesh.current.rotation.y = degrees(-135);
  };

  useFrame((state, delta) => {
    // if (state.clock.elapsedTime > 5) {
    handleOrbitControl(state, delta);
    handleCamera(state, delta);
    // }
    // Controls
    let { forward, backward, leftward, rightward } = getKeys();
    let impulse = { x: 0, y: 0, z: 0 };
    let torque = { x: 0, y: 0, z: 0 };
    let impulseStrength = 0.75 * delta;
    let torqueStrength = 1 * delta;
    let angle = mesh.current.rotation.y;
    let maxAngle = angle;
    let deltaAngle = 0;
    forward = forward || checkJoystickDir("forward");
    backward = backward || checkJoystickDir("backward");
    leftward = leftward || checkJoystickDir("leftward");
    rightward = rightward || checkJoystickDir("rightward");
    let moving = forward || backward || leftward || rightward;
    if (rightward) {
      impulse.x += impulseStrength;
      maxAngle = degrees(0 + deltaAngle);
    }
    if (leftward) {
      impulse.x -= impulseStrength;
      maxAngle = degrees((Math.sign(angle) + 0.0001) * 180 + deltaAngle);
    }
    if (forward) {
      maxAngle = degrees(90 + deltaAngle);
      if (
        Math.sign(mesh.current.rotation.y) < 0 &&
        Math.abs(mesh.current.rotation.y) > maxAngle
      ) {
        mesh.current.rotation.y =
          degrees(360) - Math.abs(mesh.current.rotation.y);
        angle = mesh.current.rotation.y;
      }
      impulse.z -= impulseStrength;
    }
    if (backward) {
      if (
        Math.sign(mesh.current.rotation.y) > 0 &&
        radians(mesh.current.rotation.y) > 95
      ) {
        mesh.current.rotation.y =
          Math.abs(mesh.current.rotation.y) - degrees(360);
        angle = mesh.current.rotation.y;
      }
      impulse.z += impulseStrength;
      maxAngle = degrees(-90 + deltaAngle);
    }

    if (forward && rightward) {
      maxAngle = degrees(45);
    }

    if (forward && leftward) {
      maxAngle = degrees(135);
    }

    if (backward && leftward) {
      maxAngle = degrees(-135);
    }

    if (backward && rightward) {
      maxAngle = degrees(-45);
    }

    body.current.applyImpulse(impulse);
    body.current.applyTorqueImpulse(torque);
    mesh.current.rotation.y += (maxAngle - angle) / 10;
    setIsMoving(moving);
    if (moving) {
      setPlayerPos(body.current.translation());
    }
    const origin = body.current.translation();
    origin.y -= 0.06;
    let direction = { x: 0, y: -1, z: 0 };
    let ray = new rapier.Ray(origin, direction);

    const hit = rapierWorld.castRay(ray, 10, true);
    if (!hit) return;
    if ((is_jumping && body.current.translation().y < 0.02) || hit.toi == 0) {
      setIsJumping(false);
    } else if (
      // (!is_jumping && body.current.translation().y > 0.02) ||
      !is_jumping &&
      hit.toi > 0.2
    ) {
      setIsJumping(true);
    }
  });

  let handleCamera = (state, delta) => {
    if (enable_orbit) return;
    let bodyPosition = body.current.translation();
    // console.log(bodyPosition);
    let cameraPosition = new THREE.Vector3();
    // cameraPosition.copy(state.camera.position);

    cameraPosition.copy(bodyPosition);
    cameraPosition.x += -4;
    cameraPosition.z += 12;
    cameraPosition.y += 12;
    let minDistance = 0;
    let cameraTarget = new THREE.Vector3();
    if (camera_target) {
      cameraTarget.copy(camera_target);
      // minDistance = 20;
    } else {
      cameraTarget.copy(bodyPosition);
      // minDistance = 0;
      cameraTarget.y -= -1;
    }
    cameraTarget.z += 2;

    smoothedCameraPosition.lerp(cameraPosition, 3 * delta);
    smoothedCameraTarget.lerp(cameraTarget, 3 * delta);
    smoothedMinDistance.lerp(new THREE.Vector3(minDistance, 0, 0), 0.2 * delta);

    orbit.current.minDistance = smoothedMinDistance.x;

    state.camera.lookAt(smoothedCameraTarget);
    orbit.current.target.set(
      smoothedCameraTarget.x,
      smoothedCameraTarget.y,
      smoothedCameraTarget.z
    );
    camera_target_mesh.current.position.copy(smoothedCameraPosition);
    // saving pos refs for light
    state.camera.userData.target_position = smoothedCameraTarget;
    state.camera.userData.default_camera_position =
      camera_target_mesh.current.position;
  };

  let handleOrbitControl = (state, delta) => {
    if (enable_orbit) return;
    let cameraPosition = new THREE.Vector3();
    cameraPosition.copy(camera_target_mesh.current.position);
    if (!is_orbiting) {
      // state.camera.position.copy(cameraPosition)
      smoothedOrbitTarget.lerp(cameraPosition, 3 * delta);
      state.camera.position.copy(smoothedOrbitTarget);

      // let anim = gsap.to(state.camera.position, {
      //   x: cameraPosition.x,
      //   y: cameraPosition.y,
      //   z: cameraPosition.z,
      // });

      // setSmoothCameraGsap(anim);
    } else {
      smoothedOrbitTarget.lerp(state.camera.position, 3 * delta);
      smoothedOrbitTarget.copy(smoothedOrbitTarget);
      // if (smooth_camera_gsap) {
      //   smooth_camera_gsap.kill();
      // }
    }
  };

  return (
    <group ref={group} {...props} dispose={null}>
      <OrbitControls
        ref={orbit}
        makeDefault
        minPolarAngle={degrees(20)}
        maxPolarAngle={degrees(85)}
        maxDistance={20}
        minAzimuthAngle={degrees(-120)}
        maxAzimuthAngle={degrees(80)}
        enableZoom={enable_orbit}
        enableDamping={false}
        onStart={() => {
          setIsOrbiting(true);
        }}
        onEnd={() => {
          setIsOrbiting(false);
        }}
      />

      <mesh ref={camera_target_mesh} visible={false}>
        <boxGeometry args={[0.2, 0.2, 0.2]} />
        <meshBasicMaterial color={"red"} />
      </mesh>

      <RigidBody
        ref={body}
        name="player"
        colliders={false}
        restitution={0}
        friction={0.3}
        linearDamping={0.5}
        angularDamping={0.5}
        // position-y={2}
        lockRotations
        enabledRotations={[false, false, false]}
        position={[init_pos.x, init_pos.y, init_pos.z]}
        //
      >
        <group
          onClick={(event) => {
            event.stopPropagation();
            if (is_moving || is_jumping) return;
            setAnimationName("Handshake");
            turnToCamera();
            if (!isPlaying("robot")) {
              fadeInSound("robot", 500);
              playSound("hi");
            }
          }}
        >
          <primitive ref={mesh} object={scene} />
        </group>
        <CapsuleCollider
          args={[-1, 0.51]}
          scale={0.365}
          position={[0, 0.55, 0]}
        />
      </RigidBody>
    </group>
  );
}

useGLTF.preload("./models/robot/robot2.glb");
