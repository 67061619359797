import { Center, GradientTexture, useTexture } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { CuboidCollider, RigidBody } from "@react-three/rapier";
import { useEffect, useRef, useState } from "react";
import useGame from "../../store/useGame";
import CalcWorldPos from "../../utils/CalcWorldPos";
import * as THREE from "three";
import { degrees, radians } from "radians";
import BasicCustomShader from "../shaders/FloorShader";

let Plane = (props) => {
  let block = props.block;
  let scale = props.scale;
  let group = useRef();
  let ground = useRef();
  let radius = scale / 3;
  let far = scale / 1.4;
  let border = useRef();
  let helpers = !!false;

  let player_pos = useGame((state) => state.player_pos);
  let setFloorBlocksUpdated = useGame((state) => state.setFloorBlocksUpdated);
  let floor_blocks = useGame((state) => state.floor_blocks);
  let addFloorBlock = useGame((state) => state.addFloorBlock);

  // useFrame(() => {
  //   let border_pos = CalcWorldPos(border.current);
  //   let distance = player_pos.distanceTo(border_pos);
  //   if (distance > radius && distance < far) {
  //     border.current.material.color = new THREE.Color("red");
  //     let angle = calcAngle();
  //     calcNextBlock(radians(angle));
  //   } else {
  //     border.current.material.color = new THREE.Color("white");
  //   }
  // });

  let calcAngle = () => {
    let vec_helper = new THREE.Vector3();
    let relative_player_pos = vec_helper.subVectors(
      group.current.position,
      player_pos
    );
    relative_player_pos.add(new THREE.Vector3(0, 0, -4));
    let relative_border_pos = new THREE.Vector3(-0.01, 0, 0);
    // console.log(relative_border_pos, relative_player_pos);
    let angle = relative_border_pos.angleTo(relative_player_pos);
    var orientation =
      relative_border_pos.x * relative_player_pos.z -
      relative_border_pos.z * relative_player_pos.x;
    if (orientation > 0) angle = 2 * Math.PI - angle;
    return angle;
  };

  let calcNextBlock = (angle) => {
    if (angle < 20 || angle > 340) {
      //right
      addBlock(1, 0);
    } else if (angle > 70 && angle < 110) {
      //top
      addBlock(0, -1);
    } else if (angle > 160 && angle < 200) {
      //left
      addBlock(-1, 0);
    } else if (angle > 250 && angle < 290) {
      //bottom
      addBlock(0, 1);
    } else if (angle > 20 && angle < 70) {
      //right top
      addBlock(1, 0);
      addBlock(0, -1);
      addBlock(1, -1);
    } else if (angle > 110 && angle < 160) {
      //left top
      addBlock(0, -1);
      addBlock(-1, 0);
      addBlock(-1, -1);
    } else if (angle > 200 && angle < 250) {
      //left bottom
      addBlock(-1, 0);
      addBlock(0, 1);
      addBlock(-1, 1);
    } else if (angle > 290 && angle < 340) {
      //right bottom
      addBlock(1, 0);
      addBlock(0, 1);
      addBlock(1, 1);
    }
  };

  let addBlock = (x, z) => {
    let new_block = { x: block.x + x, z: block.z + z };
    if (!floor_blocks[new_block.x + "" + new_block.z]) {
      // console.log("added!");
      addFloorBlock(new_block);
      setFloorBlocksUpdated();
    }
  };

  return (
    <group {...props} ref={group} scale={1}>
      <RigidBody colliders={false} type="fixed">
        <Center position-y={-2}>
          <mesh
            ref={ground}
            material={props.material}
            receiveShadow
            onClick={(event) => {
              // event.stopPropagation();
            }}
            // scale={[1, 4, 1]}
          >
            <boxGeometry args={[1.62 * props.scale, 4, 1 * props.scale]} />
            {/* <meshDepthMaterial
              depthPacking={THREE.RGBADepthPacking}
              onBeforeCompile={(shader) => {
              }}
            /> */}
          </mesh>
        </Center>
        <CuboidCollider
          args={[(1.62 * props.scale) / 2, 2, (1 * props.scale) / 2]}
          // scale={[(1.62 * props.scale) / 2, 2, (1 * props.scale) / 2]}
          position={[0, -2, 0]}
        />
        <CuboidCollider
          args={[(1.62 * props.scale) / 2, (1 * props.scale) / 4, 2]}
          position={[0, (1 * props.scale) / 4, (1 * props.scale) / 2.5]}
        />
        <CuboidCollider
          args={[(1.62 * props.scale) / 2, (1 * props.scale) / 4, 2]}
          position={[0, (1 * props.scale) / 4, -(1 * props.scale) / 2.5]}
        />
        <CuboidCollider
          args={[2, (1 * props.scale) / 4, (1 * props.scale) / 2]}
          position={[(1 * props.scale) / 2, (1 * props.scale) / 4, 0]}
        />
        <CuboidCollider
          args={[2, (1 * props.scale) / 4, (1 * props.scale) / 2]}
          position={[-(1 * props.scale) / 2, (1 * props.scale) / 4, 0]}
        />
      </RigidBody>

      <mesh ref={border} position-x={0.01} visible={helpers}>
        <sphereGeometry args={[radius]} />
        <meshBasicMaterial wireframe />
      </mesh>
      <mesh position-x={0.01} visible={helpers}>
        <sphereGeometry args={[far]} />
        <meshBasicMaterial wireframe color={"yellow"} />
      </mesh>
    </group>
  );
};

let Floor = (props) => {
  let [floor] = useTexture(["./textures/floor/floor-invert.png"], ([floor]) => {
    floor.repeat.x = 10;
    floor.repeat.y = 16;
    floor.wrapS = THREE.MirroredRepeatWrapping;
    floor.wrapT = THREE.MirroredRepeatWrapping;
  });
  let scale = 200;
  let material = useRef();
  let { floor_blocks } = useGame((state) => state);

  // useEffect(() => {
  //   let unsubscribeReset = useGame.subscribe(
  //     (state) => {
  //       return state.floor_blocks_updated;
  //     },
  //     (value) => {}
  //   );
  //   return () => {
  //     unsubscribeReset();
  //     // ...
  //   };
  // }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      material.current.iResolution.set(
        window.innerWidth,
        window.innerHeight,
        1
      );
    });
  }, []);

  return (
    <>
      <group position={[4, 0, -4]}>
        <floorShader
          ref={material}
          uTexture={floor}
          lights={true}
          fog={!!window.scene.fog}
          dithering={true}
          fogColor={window.scene.fog?.color}
          fogNear={window.scene.fog?.near}
          fogFar={window.scene.fog?.far}
        />
        <group>
          {Object.values(floor_blocks).map((block, i) => {
            return (
              <Plane
                key={i}
                position={[block.x * scale, 0, block.z * scale]}
                material={material.current}
                block={block}
                scale={scale}
              />
            );
          })}
        </group>
      </group>
    </>
  );
};

export default Floor;
