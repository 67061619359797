import { Center, GradientTexture, Text, Text3D } from "@react-three/drei";
import { useControls } from "leva";
import { degrees } from "radians";
import SplittedText from "../SplittedText";

const CreatorText = (props) => {
  return (
    <Center
      {...props}
      position={[1.5, 0.4, 1.2]}
      rotation={[degrees(-90), 0, degrees(0)]}
      scale={0.25}
    >
      {/* <Text3D
        castShadow
        receiveShadow
        lineHeight={0.6}
        // material={text_material}
        font={"./font/carter.json"}
      >
        {
          "My creator's name is\nSina Gholizade\nI've learned\neverything from him"
        }
        <meshStandardMaterial color={"#474747"} />
      </Text3D> */}

      <Text
        scale={10}
        font="./font/carter.ttf"
        // rotation-x={degrees(-90)}
        position={[0, 0.02, 0]}
      >
        {
          "My creator's name is\nSina Gholizade\nI've learned\neverything from him"
        }
        <meshStandardMaterial color={"#474747"} />
      </Text>
    </Center>
  );
};

export default CreatorText;
