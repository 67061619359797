import { Clone, useGLTF, useTexture } from "@react-three/drei";
import { CuboidCollider, RigidBody } from "@react-three/rapier";
import { degrees } from "radians";
import { useEffect, useRef } from "react";
import useGame from "../../store/useGame";
import { playSound } from "../../utils/AudioController";
import FakeShadowObj from "../../utils/FakeShadowObj";
import Distance from "../Distance";

export default function LogoCube(props) {
  let addShadowObjects = useGame((state) => state.addShadowObjects);
  let body = useRef();
  let mesh = useRef();
  const model = useGLTF("./models/logo-cube/logo-cube.glb");
  let icons = {
    js: "./textures/cube-icons/js.png",
    electron: "./textures/cube-icons/electron.jpg",
    gsap: "./textures/cube-icons/gsap.png",
    laravel: "./textures/cube-icons/laravel.jpg",
    react: "./textures/cube-icons/react.png",
    webflow: "./textures/cube-icons/webflow.png",
    aws: "./textures/cube-icons/aws.png",
  };

  let texture = useTexture(icons[props.type || "js"], (logo) => {
    logo.flipY = false;
    // materials.code.map = logo;
  });

  useEffect(() => {
    model.scene.traverse((k) => {
      k.castShadow = true;
    });

    addShadowObjects(
      FakeShadowObj(
        "Logo Cube",
        body.current,
        mesh.current,
        0.8,
        0.8,
        0.6,
        0.1,
        "rect"
      )
    );
  }, []);

  return (
    <group {...props} dispose={null} scale={0.3}>
      <RigidBody
        ref={body}
        colliders={false}
        onCollisionEnter={(e) => {
          if (e.colliderObject.parent.name == "player") {
            playSound("hit", Math.random());
          }
        }}
      >
        <Distance>
          <group ref={mesh}>
            <Clone
              object={model.scene}
              rotation-y={degrees(180)}
              inject={
                <meshStandardMaterial
                  map={texture}
                  metalness={0.3}
                  roughness={0}
                />
              }
            />
          </group>
        </Distance>
        <CuboidCollider args={[1, 1, 1]} mass={0.05} />
      </RigidBody>
    </group>
  );
}

useGLTF.preload("./models/logo-cube/logo-cube.glb");
