import {
  Center,
  GradientTexture,
  Text,
  useGLTF,
  useMatcapTexture,
  useTexture,
} from "@react-three/drei";
import { CuboidCollider, RigidBody } from "@react-three/rapier";
import { degrees } from "radians";
import * as THREE from "three";
import { useEffect, useRef, useState } from "react";
import Distance from "../Distance";
import LogoCube from "./logo-cube";
import FocusBox from "../FocusBox";
import Link from "../Link";
import { useControls } from "leva";

export default function IconlyApp(props) {
  let model = useGLTF("./models/iconly-app/iconly-app.glb");
  const window_model = useGLTF("./models/iconly-app/windows.glb");
  const [windows_matcap] = useMatcapTexture(
    3, // index of the matcap texture https://github.com/emmelleppi/matcaps/blob/master/matcap-list.json
    256 // size of the texture ( 64, 128, 256, 512, 1024 )
  );
  let apple_model = useGLTF("./models/iconly-app/apple.glb");
  const [apple_matcap] = useMatcapTexture(
    550, // index of the matcap texture https://github.com/emmelleppi/matcaps/blob/master/matcap-list.json
    256 // size of the texture ( 64, 128, 256, 512, 1024 )
  );

  let gradient_material = useRef();

  let baked_texture = useTexture(
    "./textures/shadow/baked_shadow.jpg",
    (texture) => {
      texture.flipY = false;
    }
  );
  useEffect(() => {
    model.scene.traverse((k) => {
      if (k.name == "app_floor" || k.name == "cube_2" || k.name == "Cube012" || k.name == "cube_1" || k.name == "cube_3" ) {
        k.material = new THREE.MeshBasicMaterial({
          map: baked_texture,
          color: "#FAFAFA",
        });
      }
    });
  }, []);
  return (
    <group {...props}>
      <meshStandardMaterial ref={gradient_material} color={"#424242"}>
        <GradientTexture
          stops={[0, 1]} // As many stops as you want
          colors={["#90A4AE", "#ECEFF1"]} // Colors need to match the number of stops
          size={1024} // Size is optional, default = 1024
        />
      </meshStandardMaterial>
      <Distance static>
        <RigidBody colliders={false} type="fixed" position={[2.2, 0, 6.8]}>
          <group position={[0.3, 0, 0.3]}>
            <Center top position-y={-0.32}>
              <primitive object={model.scene} rotation-y={degrees(-70)} />
            </Center>
          </group>
          <CuboidCollider args={[2.1, 1, 2.1]} position={[0.1, 1, 0.3]} />
        </RigidBody>
      </Distance>
      <group position={[2.2, 0, 6.5]}>
        <group scale={0.5} position={[0, 0, 2.2]}>
          <Distance position={[1, 0, 5.5]}>
            <RigidBody>
              <Center scale={0.3} rotation-y={degrees(-90)}>
                <mesh
                  geometry={window_model.nodes.windows.geometry}
                  material={window_model.nodes.windows.material}
                  position={[18.27, 0, 6.26]}
                >
                  <meshMatcapMaterial matcap={windows_matcap} />
                </mesh>
              </Center>
            </RigidBody>
          </Distance>
          <Distance position={[-1, 0, 5.5]}>
            <RigidBody>
              <Center scale={0.3} rotation-y={degrees(-90)}>
                <mesh
                  geometry={apple_model.nodes.apple.geometry}
                  material={apple_model.nodes.apple.material}
                  position={[18.05, -0.03, -4.65]}
                  rotation={[0, Math.PI / 2, 0]}
                >
                  <meshMatcapMaterial matcap={apple_matcap} />
                </mesh>
              </Center>
            </RigidBody>
          </Distance>
        </group>

        <Text
          scale={8}
          font="./font/carter.ttf"
          rotation-x={degrees(-90)}
          position={[0, 0.02, 4]}
        >
          {"Iconly App"}
        </Text>
        <Text
          scale={5}
          font="./font/carter.ttf"
          rotation-x={degrees(-90)}
          position={[0, 0.02, 6]}
        >
          {"Role: Frontend developer"}
          {/* Electron js */}
        </Text>
        <LogoCube
          type="electron"
          position={[-2.5, 0, 5]}
          rotation-y={degrees(20)}
        />

        <FocusBox
          scale={4}
          position={[0, 0, 4]}
          camera_target_position={[0, 2, -2]}
        />

        <Link url="https://iconly.pro/download" position={[3, 0, 4.8]} />
      </group>
    </group>
  );
}
