/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef, useState } from "react";
import { useGLTF, useAnimations, useTexture } from "@react-three/drei";
import { degrees, radians } from "radians";
import gsap from "gsap";
import * as THREE from "three";
import { clone as SkeletonUtilsClone } from "three/examples/jsm/utils/SkeletonUtils";
gsap.defaults({ overwrite: "auto" });

export function Robot(props) {
  const group = useRef();
  let face_textures = useTexture(
    ["./models/robot/faces/dark/yoga.png"],
    (faces) => {
      faces.forEach((k) => {
        k.flipY = false;
        k.wrapS = THREE.RepeatWrapping;
        k.wrapT = THREE.RepeatWrapping;
        k.encoding = THREE.sRGBEncoding;
      });
    }
  );
  let faces = {
    yoga: face_textures[0],
  };

  const { scene, nodes, animations } = useGLTF("./models/robot/robot2.glb");
  const { actions, names } = useAnimations(animations, group);
  let [screen, setScreen] = useState();
  let animationOptions = ["Coding", "Yoga"];
  let [animationName, setAnimationName] = useState(
    props.animate || animationOptions[0]
  );

  useEffect(() => {
    let mesh = SkeletonUtilsClone(scene);
    mesh.rotation.y = 0;
    mesh.traverse((mesh) => {
      if (mesh.name == "screen" || mesh.name == "screen001") {
        setScreen(mesh);
      }
    });
    group.current.add(mesh);

    group.current.traverse((mesh, i) => {
      mesh.castShadow = true;
      mesh.receiveShadow = true;
      mesh.frustumCulled = false;
    });

    return () => {
      group.current.children = [];
    };
  }, []);

  useEffect(() => {
    if (props.face && screen) {
      screen.material = new THREE.MeshStandardMaterial({
        map: faces[props.face],
      });
    }
  }, [screen]);

  useEffect(() => {
    animate();
  }, [animationName]);

  let animate = () => {
    let action = actions[animationName];
    if (poseFixes[animationName]) {
      poseFixes[animationName]();
    }
    action.fadeIn().play();
    return () => {
      action.fadeOut();
    };
  };

  let Coding = () => {
    group.current.rotation.y = degrees(-90);
    group.current.position.y = 0.11;
    group.current.position.x = -0.08;
    group.current.position.z = -0.82;
  };

  let Yoga = () => {
    // yoga_screen
    // console.log({ screen });
    // screen.material.map(yoga_screen);
  };

  let poseFixes = {
    Coding,
    Yoga,
  };
  return <group ref={group} {...props} dispose={null}></group>;
}

useGLTF.preload("./models/robot/robot2.glb");
