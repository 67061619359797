import create from "zustand";
import * as THREE from "three";
import { subscribeWithSelector } from "zustand/middleware";

let useGame = create(
  subscribeWithSelector((set) => ({
    player_pos: new THREE.Vector3(),
    player_distance: 20,
    game_loaded: false,
    floor_blocks: { "00": { x: 0, z: 0 } },
    floor_blocks_updated: 0,
    joystick_direction: [],
    joystick_jump: false,
    joystick_reset: false,
    camera_target: null,
    bloom_obj: [],
    shadow_objects: [],
    shadow_light_ref: null,
    setPlayerPos: (pos) => set({ player_pos: pos }),
    setCameraTarget: (vec3) => set({ camera_target: vec3 }),
    setShadowLightRef: (ref) => set({ shadow_light_ref: ref }),
    addShadowObjects: (obj) =>
      set((state) => {
        let tmp = [...state.shadow_objects, obj];
        return { shadow_objects: tmp };
      }),
    addBloomObj: (obj) =>
      set((state) => {
        return { bloom_obj: [...state.bloom_obj, obj] };
      }),
    setGameLoaded: (loaded) => set({ game_loaded: loaded }),
    setJoystickJump: (jumped) => set({ joystick_jump: jumped }),
    setJoystickReset: (reset) => set({ joystick_reset: reset }),
    setJoystickDirection: (dir) => set({ joystick_direction: dir }),
    addFloorBlock: (block) =>
      set((state) => {
        state.floor_blocks[block.x + "" + block.z] = block;
        //   console.log(state.floor_blocks);
        return { floor_blocks: state.floor_blocks };
      }),
    setFloorBlocksUpdated: () =>
      set({ floor_blocks_updated: new Date().getTime() }),
  }))
);

export default useGame;
