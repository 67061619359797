import "./style.css";
import ReactDOM from "react-dom/client";
import { Canvas, useFrame } from "@react-three/fiber";
import Experience from "./Experience.js";
import { Leva } from "leva";
import * as THREE from "three";
import { KeyboardControls, Loader, Stats } from "@react-three/drei";
import HUD from "./HUD";
import LoaderScreen from "./Loader";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";

THREE.ColorManagement.legacyMode = false;
const root = ReactDOM.createRoot(document.querySelector("#root"));

let created = ({ gl, scene, camera }) => {
  // console.log(camera);
  window.scene = scene;
  // camera.fov = 45;
  camera.lookAt(new THREE.Vector3(-2, 0, 0));
  window.addEventListener("resize", () => {
    if (window.innerWidth < 660) {
      camera.fov = 75;
    } else {
      camera.fov = 45;
    }
  });
  // gl.setClearColor("#ff0000" , 0.5)
  // scene.background = new THREE.Color("#ff0000")
};

let App = () => {
  let [is_debug, setIsDebug] = useState(false);
  useEffect(() => {
    setIsDebug(window.location.hash == "#debug");
  }, []);
  return (
    <>
      <HUD />
      <Leva hidden={!is_debug} />
      {is_debug ? (
        <>
          <Stats showPanel={0} className="stats" />
        </>
      ) : null}
      <KeyboardControls
        map={[
          { name: "forward", keys: ["ArrowUp", "KeyW"] },
          { name: "backward", keys: ["ArrowDown", "KeyS"] },
          { name: "leftward", keys: ["ArrowLeft", "KeyA"] },
          { name: "rightward", keys: ["ArrowRight", "KeyD"] },
          { name: "jump", keys: ["Space"] },
          { name: "reset_char", keys: ["KeyR"] },
        ]}
      >
        <Canvas
          id="canvas"
          shadows={!true}
          // orthographic
          camera={{
            fov: window.innerWidth < 660 ? 75 : 45,
            near: 0.1,
            far: 1000,
            position: [-4, 12, 12],
            zoom: 1.5,
          }}
          onCreated={created}
        >
          <Experience />
        </Canvas>
        <LoaderScreen />
      </KeyboardControls>
    </>
  );
};

root.render(<App />);
