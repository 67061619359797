import { Center, Text3D } from "@react-three/drei";
import { useControls } from "leva";
import { degrees } from "radians";
import FocusBox from "../FocusBox";
import SplittedText from "./SplittedText";

const IntroText = (props) => {
  return (
    <group {...props}>
      <Center position={[-1, 0, 3]} rotation-y={degrees(0)}>
        <group rotation={[degrees(-90), 0, degrees(0)]}>
          <SplittedText text={`Hi!\nI'm C-na\nA coding\nmachine`} />
        </group>
      </Center>
      <FocusBox
        scale={4}
        position={[-1, 0, 3]}
        camera_target_position={[0, 0 ,-2]}
      />
    </group>
  );
};

export default IntroText;
