import { Center, Text3D } from "@react-three/drei";
import { useControls } from "leva";
import { degrees } from "radians";
import SplittedText from "../SplittedText";

const TechText = (props) => {
  return (
    <Center {...props} position={[0.5, 0, 2.8]}>
      <group rotation={[degrees(-90), 0, degrees(0)]}>
        <SplittedText
          size={0.5}
          word_space={10}
          type="word"
          text={`I'm a fullstack`}
        />
      </group>
      <group rotation={[degrees(-90), 0, degrees(0)]} position-z={0.75}>
        <SplittedText
          type="word"
          size={0.5}
          word_space={10}
          text={`developer`}
        />
      </group>
      <group rotation={[degrees(-90), 0, degrees(0)]} position-z={1.5}>
        <SplittedText
          type="word"
          size={0.5}
          word_space={10}
          text={`Backend`}
        />
      </group>
      <group rotation={[degrees(-90), 0, degrees(0)]} position-z={2.25}>
        <SplittedText
          type="word"
          size={0.5}
          word_space={10}
          text={`Frontend`}
        />
      </group>
    </Center>
  );
};

export default TechText;
