import { Environment, useHelper } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useControls } from "leva";
import { Perf } from "r3f-perf";
import { degrees } from "radians";
import { Suspense, useEffect, useRef } from "react";
import * as THREE from "three";
import Effects from "./effects";
import Light from "./World/Light";
import World from "./World/World";
import { isMobile } from "react-device-detect";

export default function Experience() {
  const scene = useThree((state) => state.scene);
  useHelper(scene, THREE.AxesHelper, 5);
  // useHelper(directionalLightRef, THREE.DirectionalLightHelper, 1);

  // let { color } = useControls("Fog", {
  //   color: { value: "#56bbd1" },
  // });
  let color = isMobile ? "#37a1c1" : "#56bbd1";
  // #37a1c1
  return (
    <>
      <Environment
        // files={"./environmentMaps/the_sky_is_on_fire_2k.hdr"}
        files={"./environmentMaps/driving_school_1k.hdr"}
      ></Environment>

      {/* {perfVisible ? <Perf position="top-left" /> : null} */}
      {/* <Effects /> */}
      <Light />
      <color attach={"background"} args={[color]} />
      <fog attach="fog" args={[color, 25, 35]} />
      <World />
    </>
  );
}
