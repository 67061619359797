import React, { useRef } from "react";
import { Center, Clone, useGLTF } from "@react-three/drei";
import Distance from "../Distance";
import { useControls } from "leva";

export function Road1(props) {
  const { scene, materials } = useGLTF("./models/roads/road1.glb");
  return (
    <group {...props} dispose={null}>
      <Distance>
        <Center top scale={0.4}>
          <Clone
            object={scene}
            inject={<meshBasicMaterial color={"#5ac6d5"} />}
          />
        </Center>
      </Distance>
    </group>
  );
}

useGLTF.preload("./models/roads/road1.glb");
