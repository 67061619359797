import * as THREE from "three";

let CalcWorldPos = (obj, obj2 = null) => {
  obj.updateMatrixWorld();
  let handVec = new THREE.Vector3();
  obj.getWorldPosition(handVec);
  return handVec;
};

export default CalcWorldPos;
