export default function FakeShadowObj(
  name,
  target_rigid,
  target_mesh,
  width,
  height,
  offsetY = 0,
  opacity = 0.7,
  type = "circle"
) {
  return {
    name,
    target_rigid,
    target_mesh,
    width,
    height,
    offsetY,
    opacity,
    type,
  };
}
