/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Bookshelf(props) {
  const { nodes, materials } = useGLTF("./models/room/objects/bookshelf.glb");
  return (
    <group {...props} dispose={null} position-z={4.76}>
      <group position={[-1.83787, 0.89874, -6.08964]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube032_1.geometry}
          material={materials.book_papers}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube032_2.geometry}
          material={materials.book_1}
        />
      </group>
      <group position={[-1.39898, 1.10736, -6.30018]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube005.geometry}
          material={materials.shelve_body}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube005_1.geometry}
          material={materials.shelve_secondary}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube007.geometry}
          material={materials.shelve_body}
          position={[-0.23742, -0.69052, 0.38181]}
          rotation={[0, 0, Math.PI]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.shelf_hook_1.geometry}
            material={materials.shelve_part}
            position={[-0.19247, 0.01354, 0.01073]}
            rotation={[0, 0, -Math.PI]}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube008.geometry}
          material={materials.shelve_body}
          position={[0.23954, -0.69052, 0.38181]}
          rotation={[0, 0, Math.PI]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.slef_hook_2.geometry}
            material={materials.shelve_part}
            position={[0.19079, 0.01354, 0.01073]}
            rotation={[0, 0, -Math.PI]}
          />
        </mesh>
      </group>
      <group
        position={[-1.72129, 0.85933, -6.08964]}
        rotation={[0, 0, 0.06742]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube034_1.geometry}
          material={materials.book_papers}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube034_2.geometry}
          material={materials.book_3}
        />
      </group>
      <group position={[-1.7768, 0.85933, -6.08964]} rotation={[0, 0, 0.06742]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube033_1.geometry}
          material={materials.book_papers}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube033_2.geometry}
          material={materials.book_2}
        />
      </group>
      <group
        position={[-1.57353, 0.77545, -6.08964]}
        rotation={[0, 0, -1.56554]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube035.geometry}
          material={materials.book_papers}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube035_1.geometry}
          material={materials.book_1}
        />
      </group>
      <group
        position={[-0.95823, 1.36623, -6.08964]}
        rotation={[Math.PI, 0, Math.PI]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube036.geometry}
          material={materials.book_papers}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube036_1.geometry}
          material={materials.book_1}
        />
      </group>
      <group
        position={[-1.0748, 1.32682, -6.08964]}
        rotation={[-Math.PI, 0, -3.07418]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube038.geometry}
          material={materials.book_papers}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube038_1.geometry}
          material={materials.book_2}
        />
      </group>
      <group
        position={[-1.0193, 1.32682, -6.08964]}
        rotation={[-Math.PI, 0, -3.07418]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube037.geometry}
          material={materials.book_papers}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube037_1.geometry}
          material={materials.book_4}
        />
      </group>
      <group
        position={[-1.22458, 1.2346, -6.08964]}
        rotation={[-Math.PI, 0, 1.57059]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube041.geometry}
          material={materials.book_papers}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube041_1.geometry}
          material={materials.book_4}
        />
      </group>
      <group
        position={[-1.11964, 1.29482, -6.08964]}
        rotation={[-Math.PI, 0, -3.07418]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube040.geometry}
          material={materials.book_papers}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube040_1.geometry}
          material={materials.book_5}
        />
      </group>
      <group
        position={[-1.81003, 1.82746, -6.08964]}
        rotation={[0, 0, 0.19244]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube042.geometry}
          material={materials.book_papers}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube042_1.geometry}
          material={materials.book_3}
        />
      </group>
      <group position={[-1.72663, 1.24811, -6.0818]} rotation={[0, 0.31472, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube056.geometry}
          material={materials["rubic red"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube056_1.geometry}
          material={materials["rubic blue"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube056_2.geometry}
          material={materials["rubic green"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube056_3.geometry}
          material={materials["rubic yellow"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube054.geometry}
        material={materials.headphone}
        position={[-1.37604, 1.73033, -6.12273]}
        rotation={[-1.88907, -1.27624, -1.93932]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder007.geometry}
          material={materials["headphone speakers"]}
          position={[-0.0617, -0.00024, 0.00345]}
          rotation={[0, 0.30506, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder006.geometry}
          material={materials["headphone speakers"]}
          position={[0.0594, -0.00024, 0.01046]}
          rotation={[0, -0.34308, -Math.PI / 2]}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload("./models/room/objects/bookshelf.glb");
