import { shaderMaterial, useTexture } from "@react-three/drei";
import { extend, useFrame } from "@react-three/fiber";
import { degrees, radians } from "radians";
import { useEffect, useRef } from "react";
import * as THREE from "three";
import useGame from "../store/useGame";
import CalcWorldPos from "../utils/CalcWorldPos";
import MapValues from "../utils/MapValues";

let FakeShadowMaterial = shaderMaterial(
  {
    iTime: 20,
    iResolution: {
      value: new THREE.Vector3(window.innerWidth, window.innerHeight, 1),
    },
  },
  `
  varying vec2 vUv;
  void main() {
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);

    vUv = uv;
  }
  `,
  `

    varying vec2 vUv;
    

    float getFadeInWeight(vec2 uv)
    {
        float edge = 0.5 * abs(sin(0.5));
        // taken FabriceNeyret2's advice
        vec4 v = smoothstep(0., edge, vec4(uv, 1. - uv) );
        return v.x * v.y * v.z * v.w;
    }
    
    void main() {
    
      // float strength = distance(vUv, vec2(0.5));

      // vec3 blackColor = vec3(1.0);
      // vec3 uvColor = vec3(vUv, 1.0);
      // vec3 uvColor = vec3(0.0);
      // vec3 mixedColor = mix(blackColor, uvColor, strength);


      vec3 bg = vec3(1.0);
      vec3 col =  vec3(0.0);
      float alpha = getFadeInWeight(vUv);

      vec3 result = mix(bg, col, alpha);
      result = result - 1.0;


      gl_FragColor = vec4(result,alpha);
    }
  `
);

extend({ FakeShadowMaterial });

export default function FakeShadow(props) {
  let shadow_objects = useGame((state) => state.shadow_objects);
  let shadow_light_ref = useGame((state) => state.shadow_light_ref);
  let texture = useTexture("./textures/shadow/shadow.png");
  let rect_texture = useTexture("./textures/shadow/shadow-cube.png");
  let offset = 0.01;
  let opacity = 0.7;
  let items = [];
  let groups = [];

  useEffect(() => {
    return () => {
      items = [];
    };
  }, []);

  useFrame(() => {
    if (!shadow_light_ref) return;
    let angles = calcLightAngle();
    let rel_pos = calcRelativePos(angles);
    let rel_scale = calcRelativeScale(rel_pos);
    // console.log(rel_scale);
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      // console.log(item.obj.target_rigid.rotation());
      opacity = item.obj.opacity;
      let y = offset;
      let pos = item.obj.target_rigid.translation();
      let scale = 1 - pos.y + item.obj.offsetY;
      item.ref.scale.set(scale, scale, scale);

      groups[i].position.x = rel_pos.x * item.obj.width + rel_pos.x * pos.y;

      groups[i].position.z = rel_pos.z * item.obj.height + rel_pos.z * pos.y;
      // groups[i].position.z = -pos.y;
      //   console.log(scale);
      item.ref.material.opacity = opacity;
      pos.y = y;
      item.ref.position.copy(pos);
      item.ref.rotation.z = item.obj.target_mesh.rotation.y;
      item.ref.scale.x = Math.abs(rel_scale.x);
      item.ref.scale.y = Math.abs(rel_scale.z);
    }
  });

  let calcLightAngle = () => {
    // if (!shadow_light_ref) return;
    let light_pos_vec = new THREE.Vector3();
    let target_pos_vec = new THREE.Vector3();
    light_pos_vec.copy(shadow_light_ref.position);
    // target_pos_vec.copy(light.current.target.position);
    // console.log("=>",radians(light.current.target.position.angleTo(light.current.position)));
    // console.log("=>",radians(light.current.target.position.angleTo(light.current.position)));\
    light_pos_vec.sub(shadow_light_ref.target.position);
    target_pos_vec.z = 0.01;
    let z_angle = target_pos_vec.angleTo(light_pos_vec);
    target_pos_vec.z = 0;
    target_pos_vec.x = 0.01;
    let x_angle = target_pos_vec.angleTo(light_pos_vec);
    return { x: radians(x_angle), z: radians(z_angle) };
  };

  let calcRelativePos = (angles) => {
    let x_index = MapValues(angles.x, 0, 180, -1, 1, true);
    let z_index = MapValues(angles.z, 0, 180, -1, 1, true);
    return { x: x_index, z: z_index };
  };
  let calcRelativeScale = (angles) => {
    let x_index = angles.x;
    let x_sign = Math.sign(x_index);
    x_sign = x_sign == 0 ? 1 : x_sign;
    x_index = (Math.abs(x_index) + 1) * x_sign;

    let z_index = angles.z;
    let z_sign = Math.sign(z_index);
    z_sign = z_sign == 0 ? 1 : z_sign;
    z_index = (Math.abs(z_index) + 1) * z_sign;

    return { x: x_index, z: z_index };
  };

  return (
    <>
      {shadow_objects.map((k, i) => {
        return (
          <group key={i} {...props} ref={(ref) => (groups[i] = ref)}>
            <mesh
              ref={(ref) => (items[i] = { ref: ref, obj: k })}
              position={[0, offset, 0]}
              rotation-x={degrees(-90)}
            >
              <planeGeometry args={[k.width, k.height]} />
              {/* <circleGeometry args={[k.width / 2, 10]}/> */}
              {/* <boxGeometry /> */}
              <meshBasicMaterial
                color={"black"}
                alphaMap={k.type == "rect" ? rect_texture : texture}
                transparent={true}
                opacity={opacity}
                depthWrite={false}
                // blending={THREE.AdditiveBlending}
              />
            </mesh>
          </group>
        );
      })}
      {/* <mesh position={[-5.128801345825195, 0.01, 49.61209487915039]}>
        <boxGeometry />
      </mesh> */}
    </>
  );
}
