/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { degrees } from "radians";

export default function Desk(props) {
  const { scene, materials } = useGLTF("./models/room/objects/desk.glb");
  return (
    <group {...props} dispose={null} position={[-2.513, 0, 2.24]}>
      <primitive object={scene} />
    </group>
  );
}

useGLTF.preload("./models/room/objects/desk.glb");
