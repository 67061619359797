import { Center } from "@react-three/drei";
import { degrees } from "radians";
import { Road1 } from "./Road1";
import { Road2 } from "./Road2";
import { Road3 } from "./Road3";

export default function WorksRoad(props) {
  return (
    <group {...props}>
      <group position={[0, 0, 31.5]} rotation-y={degrees(-90)}>
        <Center top>
          <Road3 position={[0, 0, 0]} />
          <Road2 position={[6.02, 0, 0]} />
          <Road3 position={[12.05, 0, 0]} />
          <Road2 position={[18.07, 0, 0]} />
          <Road3 position={[24.09, 0, 0]} />
          <Road2 position={[30.12, 0, 0]} />
          <Road3 position={[36.15, 0, 0]} />
          <Road2 position={[42.18, 0, 0]} />
        </Center>
      </group>
    </group>
  );
}
