import { Center, Text3D } from "@react-three/drei";
import { useControls } from "leva";
import { degrees } from "radians";
import SplittedText from "../SplittedText";

const BioBornText = (props) => {
  return (
    <Center {...props} position={[3.5, 0, -0.5]}>
      <group rotation={[degrees(-90), 0, degrees(0)]}>
        <SplittedText type="word" text={`I'm coding`} />
      </group>
      
      <group rotation={[degrees(-90), 0, degrees(0)]} position-z={1.5}>
        <SplittedText type="word" text={`since I was born!`} />
      </group>
      <group rotation={[degrees(-90), 0, degrees(0)]} position-z={2.5}>
        <SplittedText
          type="word"
          size={0.5}
          word_space={10}
          text={`I'm 7 years old :P`}
        />
      </group>
    </Center>
  );
};

export default BioBornText;
