import { Ring, Text, useGLTF, useTexture } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";
import { degrees } from "radians";
import Distance from "../Distance";

export default function Keys(props) {
  let model = useGLTF("./models/guide/keys.glb");
  let color = "#90A4AE";
  let [arrow_texture, space_texture] = useTexture(
    ["./models/guide/arrow.png", "./models/guide/space.png"],
    (textures) => {
      textures.forEach((texture) => {
        texture.flipY = false;
      });
    }
  );

  return (
    <group {...props}>
      <Distance>
        <RigidBody type="fixed" rotation-y={degrees(-90)}>
          <primitive object={model.scene} />
        </RigidBody>
      </Distance>

      <group position-y={0.4}>
        <mesh rotation-x={degrees(-90)} position-z={-0.1} scale={0.3}>
          <planeGeometry />
          <meshBasicMaterial map={arrow_texture} transparent color={color} />
        </mesh>{" "}
        <mesh
          rotation-x={degrees(-90)}
          position-z={-1}
          rotation-z={degrees(180)}
          scale={0.3}
        >
          <planeGeometry />
          <meshBasicMaterial map={arrow_texture} transparent color={color} />
        </mesh>
        <mesh
          rotation-x={degrees(-90)}
          rotation-z={degrees(-90)}
          position-z={-0.1}
          position-x={-0.9}
          scale={0.3}
        >
          <planeGeometry />
          <meshBasicMaterial map={arrow_texture} transparent color={color} />
        </mesh>
        <mesh
          rotation-x={degrees(-90)}
          rotation-z={degrees(90)}
          position-z={-0.1}
          position-x={0.9}
          scale={0.3}
        >
          <planeGeometry />
          <meshBasicMaterial map={arrow_texture} transparent color={color} />
        </mesh>
        <mesh
          rotation-x={degrees(-90)}
          rotation-z={degrees(180)}
          position-z={0.8}
          scale={0.2}
        >
          <planeGeometry args={[2, 1]} />
          <meshBasicMaterial map={space_texture} transparent color={color} />
        </mesh>
      </group>
      <Ring
        args={[0.95, 1, 4]}
        position-y={0.02}
        rotation-x={degrees(-90)}
        rotation-z={degrees(-45)}
        scale={2.5}
      >
        <meshBasicMaterial color={"white"} depthWrite={false} />
      </Ring>
      <Text
        scale={5}
        font="./font/carter.ttf"
        rotation-x={degrees(-90)}
        position={[0, 0.02, 2.2]}
      >
        {'Press "R" to reset'}
        <meshBasicMaterial color={"white"} depthWrite={false} />
      </Text>
    </group>
  );
}
