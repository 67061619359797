/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Bed(props) {
  const { nodes, materials } = useGLTF("./models/room/objects/bed.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube.geometry}
        material={materials.bed_body}
        position={[-0.83, 0.32521, 0.84]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube009.geometry}
          material={materials.bed_inside}
          position={[0.00573, -0.05673, 0.00122]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube011.geometry}
          material={materials.pillow}
          position={[-0.82515, 0.07365, -0.00995]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube010.geometry}
          material={materials.quilt}
          position={[0.00573, -0.05673, 0.00122]}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload("./models/room/objects/bed.glb");
