import { add_sound } from "../utils/AudioController";

export default function () {
  let skate_sound = new Howl({
    src: ["./audios/skate.mp3"],
    loop: true,
    volume: 0.5,
  });
  let skate_jump_sound = new Howl({
    src: ["./audios/skate_jump.mp3"],
    volume: 0.5,
  });
  let hi_sound = new Howl({
    src: ["./audios/hi.wav"],
    volume: 0.2,
  });
  let jump_sound = new Howl({
    src: ["./audios/jump.mp3"],
    volume: 0.2,
  });
  let robot_sound = new Howl({
    src: ["./audios/robot_sound.mp3"],
    loop: true,
    volume: 0.5,
  });
  let hit_sound = new Howl({
    src: ["./audios/hit.mp3"],
    volume: 0.5,
  });

  add_sound("skate_jump", skate_jump_sound);
  add_sound("jump", jump_sound);
  add_sound("skate", skate_sound);
  add_sound("hi", hi_sound);
  add_sound("hit", hit_sound);
  add_sound("robot", robot_sound);
}
